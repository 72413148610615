import { Sakota } from '@creately/sakota';
import { Logger, Random } from 'flux-core';
import { DataType, EntityLinkType, IDataItem, IEntity, IEntityDef } from 'flux-definition';
import { DataItemFactory } from 'flux-diagram-composer';
import { chunk, difference, flattenDeep, uniqBy, uniq } from 'lodash';
import { CsvUtil } from '../../diagram/export/csv-util';
import { UserTypesenseService } from '../../user/user-typesense.svc';
import { EDataRegistry } from '../edata-registry.svc';
import { EntityModel } from '../model/entity.mdl';
import { PeopleLocator } from '../../ui/shape-data-editor/people-locator';

export class EntityImportHelper {

    static _instance: EntityImportHelper;

    static get instance() {
        if ( !EntityImportHelper._instance ) {
            EntityImportHelper._instance = new EntityImportHelper();
        }
        return EntityImportHelper._instance;
    }

    public addEntityReferences( entity: IEntity, dataItemId, val, ctx ) {
        const reversedId = dataItemId.split( '' ).reverse().join( '' );
        val.forEach( id => {
            ( entity as EntityModel ).addLink({
                id: Random.linkId(),
                eDataId: ctx.eDataId,
                entityId: id,
                type: EntityLinkType.LOOKUP,
                handshake: dataItemId,
                connectors: {},
            } as any );
            const connectedEntity = this.getEntity( id, ctx ) as EntityModel;
            const mirrorFieldId = connectedEntity.eDefId === ( entity as EntityModel ).eDefId ? reversedId : dataItemId;
            if ( !connectedEntity.data[mirrorFieldId]) {
                connectedEntity.data[mirrorFieldId] = [];
            }
            connectedEntity.data[mirrorFieldId] =
                connectedEntity.data[mirrorFieldId].concat([ entity.id ]);
            connectedEntity.addLink({
                id: Random.linkId(),
                eDataId: ctx.eDataId,
                entityId: entity.id,
                type: EntityLinkType.LOOKUP,
                handshake: mirrorFieldId,
                connectors: {},
            } as any );
        });
    }

    public removeEntityReferences( entity: IEntity, dataItemId, val, ctx ) {
        const reversedId = dataItemId.split( '' ).reverse().join( '' );
        val.forEach( id => {
            ( entity as EntityModel ).removeLink(
                ( entity as EntityModel ).getLinkId(
                    EntityLinkType.LOOKUP,
                    dataItemId, ctx.eDataId, id,
                ),
            );
            const connectedEntity = this.getEntity( id, ctx ) as EntityModel;
            const mirrorFieldId = connectedEntity.eDefId === ( entity as EntityModel ).eDefId ? reversedId : dataItemId;
            connectedEntity.data[mirrorFieldId] =
                connectedEntity.data[mirrorFieldId].filter( eid => eid !== entity.id );
            connectedEntity.removeLink(
                connectedEntity.getLinkId(
                    EntityLinkType.LOOKUP,
                    mirrorFieldId, ctx.eDataId, entity.id,
                ),
            );
        });
    }

    public createEntity( customEDef: IEntityDef, context ) {
        const entity = new EntityModel( Random.entityId(), customEDef.id );
        entity.defId = context.eDataDefId || EDataRegistry.customEdataDefId;
        entity.data = {};
        entity.shapes = {};
        if ( context.eDataDefId === EDataRegistry.customEdataDefId ) {
            entity.style = {
                shape: { ...customEDef.defaultShape.style.shape },
                bounds: { ...customEDef.defaultShape.style.bounds,
                    defaultBounds: { ...customEDef.defaultShape.style.bounds.defaultBounds },
                },
            };
        }
        // setting default data
        for ( const dId in customEDef.dataItems ) {
            const dataDef = customEDef.dataItems[dId];
            if ( dataDef.type === DataType.LOOKUP ) {
                entity.data[dId] = [];
            } else {
                entity.data[dId] = dataDef.default;
            }
        }
        return entity;
    }

    public getDataItemMapper( dataItem: IDataItem<DataType>, ctx1: any ) {
        const { ignoreMappingErrors, entityIdGetter, model, userResolverFn } = ctx1;
        const { type: dataType, id: dataItemId, options } = dataItem;
        if ( dataType === DataType.USERS ) {
            return {
                parse: str => {
                    if ( !str ) {
                        return {
                            source: {
                                id: 'collabs',
                                name: 'Collaborators',
                            },
                            people: [],
                        };
                    }
                    const emails = str.toLowerCase().split( ',' ).map( s => s.trim()).filter( Boolean );
                    return {
                        source: {
                            id: model.id,
                            name: model.name,
                        },
                        people: emails.map( entry => userResolverFn( entry )),
                    };
                },
                validator: {
                    // validate: val => {
                    //     if ( val.people.some( p => !p.id )) {
                    //         const email = val.people.find( p => !p.id ).email;
                    //         return {
                    //             message: `invalid email: '${email}'`,
                    //         };
                    //     }
                    //     return null;
                    // },
                    validate: () => null,
                },
            };
        } else if ( dataType === DataType.LOOKUP ) {
            return {
                resolver: ( entity, str ) => ( ctx => {
                    let val = str && str.length > 0 && str !== '#N/A' ? str.split( ',' )
                        .map( s => s.trim())
                        .filter( s => s.length > 0 )
                        .map( id => entityIdGetter( id, ctx )) : [];
                    if ( ignoreMappingErrors ) {
                        val = val.filter( eId => !!eId );
                    } else {
                        if ( val.some( eId => !eId )) {
                            throw new Error( 'Invalid reference value' );
                        }
                    }
                    entity.data[dataItemId] = val;
                    if ( ctx.existingEntities[entity.id]) {
                        if ( !entity.data.__sakota__.hasChanges( dataItemId )) {
                            return;
                        }
                        const currVal = ctx.existingEntities[entity.id].data[dataItemId];
                        if ( !currVal || currVal.length === 0 ) {
                            this.addEntityReferences( entity, dataItemId, val, ctx );
                        } else if ( val.length === 1 && currVal.length === 1 ) {
                            this.removeEntityReferences( entity, dataItemId, currVal, ctx );
                            this.addEntityReferences( entity, dataItemId, val, ctx );
                        } else {
                            const toBeRemoved = difference( currVal, val );
                            const toBeAdded = difference( val, currVal );
                            this.removeEntityReferences( entity, dataItemId, toBeRemoved, ctx );
                            this.addEntityReferences( entity, dataItemId, toBeAdded, ctx );
                        }
                    } else {
                        this.addEntityReferences( entity, dataItemId, val, ctx );
                    }
                }),
                validator: {
                    validate: () => null,
                },
            };
        }
        return {
            parse: CsvUtil.getParser( dataItem.type ),
            validator: DataItemFactory.instance.create({
                ...dataItem,
                validationRules: dataType === DataType.NUMBER ? {
                    decimal: true,
                } : {},
                typeParams: dataType === DataType.OPTION_LIST ? { options } : {},
            }),
        };
    }

    public async fetchUsers(
        rows: string[][],
        mappings: any[],
        customEDef: IEntityDef,
        userSearchService: UserTypesenseService,
        peopleLocator: PeopleLocator,
    ) {
        const isTeamUser = await userSearchService.isTeamUser.toPromise();
        const collabs = await peopleLocator.getCollabs().toPromise();
        if ( !isTeamUser ) {
            return collabs.people;
        }
        const columnIndexes = mappings.filter( m => customEDef.dataItems[m.dataItemId].type === DataType.USERS )
            .map( m => m.columnIndex );
        const emails = uniq( rows.reduce(( list, row ) =>
            list.concat( columnIndexes
                .reduce(( acc, idx ) => row[idx] ? acc.concat( row[idx].split( ',' )
                    .map( s => s.trim())) : acc, [])), [])
            .filter( email => email.length > 0 ));
        if ( emails.length === 0 ) {
            return collabs.people;
        }
        const batches = chunk( emails, 50 );
        const results = await Promise.all( batches.map( batch =>
            userSearchService.searchTeamUsers( batch ).toPromise()));
        return uniqBy( collabs.people.concat( flattenDeep( results ).map( user =>
            ({
                id : user.uid,
                email: user.email,
                fullName: `${user.first_name} ${user.last_name}`,
            }))), 'email' );
    }

    protected getEntity( entityId, ctx ): IEntity {
        if ( ctx.entities[ entityId ]) {
            return ctx.entities[ entityId ];
        }
        if ( ctx.existingEntities[ entityId ]) {
            ctx.entities[ entityId ] = Sakota.create( ctx.existingEntities[ entityId ]);
            return ctx.entities[ entityId ];
        }
        Logger.error( 'entity not found. id: ', entityId );
        throw new Error( 'Invalid entity id' );
    }
}
