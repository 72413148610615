import { IPeople } from '../../ui/shape-data-editor/people-locator';

const isValidEmail = ( str: string ) => {
    // Regular expression for validating an email
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;

    // Test the email string against the regular expression
    return emailRegex.test( str );
};

type SerchUserFn = ( a: string ) => IPeople;

export const getUserResolverFn = ( userData: IPeople[]): SerchUserFn => {
    const usersByEmail = {};
    const usersByFullName = {};
    userData.forEach( user => {
        if ( user.email ) {
            usersByEmail[user.email.toLowerCase()] = user;
        }
        if ( user.fullName ) {
            usersByFullName[user.fullName.toLowerCase()] = user;
        }
    });
    return ( entry: string ) => {
        if ( isValidEmail( entry )) {
            if ( usersByEmail[entry]) {
                return usersByEmail[entry];
            }
            return {
                id: btoa( entry ),
                email: entry,
                fullName: entry,
                hasImage: true,
                image: 'https://upload.wikimedia.org/wikipedia/commons/thumb/4/46/White_square_with_question_mark.svg/2048px-White_square_with_question_mark.svg.png', // This need to be replaced
            };
        }
        if ( usersByFullName[entry]) {
            return usersByFullName[entry];
        }
        return {
            id: btoa( entry ),
            email: null,
            fullName: entry,
            hasImage: true,
            image: 'https://upload.wikimedia.org/wikipedia/commons/thumb/4/46/White_square_with_question_mark.svg/2048px-White_square_with_question_mark.svg.png', // This need to be replaced
        };
    };
};
