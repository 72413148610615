import { Injectable } from '@angular/core';
import { Proxied, Sakota } from '@creately/sakota';
import { CommandService, Logger } from 'flux-core';
import { DataType, IDataItem, IEntityDef, IValidationError } from 'flux-definition/src';
import { chunk } from 'lodash';
import { concat, defer, of, throwError } from 'rxjs';
import { fromPromise } from 'rxjs/internal-compatibility';
import { catchError, last, mapTo, switchMap, tap } from 'rxjs/operators';
import { DataSourceDomain } from '../../../editor/ui/data-sources/model/data-source.model';
import { NangoService } from '../../../editor/ui/data-sources/nango-service';
import { EDataCommandEvent } from '../command/edata-command-event';
import { EDataRegistry } from '../edata-registry.svc';
import { EDataModel, IDataSourceGoogleSheet } from '../model/edata.mdl';
import { EntityModel } from '../model/entity.mdl';
import { EntityImportHelper } from './entity-import-helper';
import { CSVImportValidationError, ICsvMappingError } from './error/csv-import-validation-error';
import { getUserResolverFn } from './shared';
import { UserTypesenseService } from '../../user/user-typesense.svc';
import { PeopleLocator } from '../../ui/shape-data-editor/people-locator';

@Injectable()
export class GoogleSheetDataImporter {

    protected batchSize = 1000;

    constructor(
        private commandSvc: CommandService,
        private nangoSvc: NangoService,
        private userSearchService: UserTypesenseService,
        private pl: PeopleLocator,
    ) {}

    public refreshData( model: EDataModel, proceedWithErrors = false ) {
        if ( !model.dataSource || model.dataSource.domain !== DataSourceDomain.GoogleSheets ) {
            throw new Error( 'Invalid data source' );
        }
        const { id: spreadsheetId } = model.dataSource as IDataSourceGoogleSheet;
        // order of types to update ???? -> same as imported order
        const obs = Object.keys( model.dataSourceMappings ).map( eDefId => {
            const { updatedBy, ...config } = model.dataSourceMappings[eDefId];
            return defer(() => this.importData( model, {
                ...config,
                eDefId,
                spreadsheetId,
            })).pipe(
                proceedWithErrors ? catchError( e => {
                    Logger.error( 'Error refreshing data', e );
                    return of({
                        entities: [],
                        errors: [ e ],
                    });
                }) : tap({
                    error: e => {
                        Logger.error( 'Error refreshing data', e );
                    },
                }),
            );
        });
        return concat( ...obs );
    }

    public importData( model: EDataModel, config: any ) {
        const { eDefId, ignoreMappingErrors, mappings } = config;
        const ctx: any = {
            eDataId: model.id,
            eDataDefId: model.defId,
            mappingExists: false,
            existingEntities: model.entities,
            model,
        };
        if ( model.isCustom && !model.customEntityDefs.hasOwnProperty( eDefId )) {
            const errMessage = `Custom entity definition not found. defId: '${eDefId}'`;
            return throwError( new CSVImportValidationError( errMessage ));
        }
        if ( model.dataSource ) {
            if ( model.dataSource.domain !== DataSourceDomain.GoogleSheets
                || ( model.dataSource as any ).id !== config.spreadsheetId ) {
                const errMessage = `Invalid data source. domain: '${model.dataSource.domain}'`;
                return throwError( new CSVImportValidationError( errMessage ));
            }
            if ( model.dataSourceMappings[eDefId]) {
                const { sheet, rowIdentifierField } = model.dataSourceMappings[eDefId];
                if ( sheet.sheetId !== config.sheet.sheetId || rowIdentifierField !== config.rowIdentifierField ) {
                    const errMessage = 'mapping already exists and differs from current mappings';
                    return throwError( new CSVImportValidationError( errMessage ));
                }
                ctx.mappingExists = true;
            } else {
                for ( const entityDefId in model.dataSourceMappings ) {
                    if ( model.dataSourceMappings[entityDefId].sheet.sheetId === config.sheet.sheetId ) {
                        const message = 'sheet is already mapped to a different object type';
                        throwError( new CSVImportValidationError( message ));
                    }
                }
            }
        }
        const idMap = {};
        Object.values( model.entities ).filter( e => e.dataSource ).forEach( e => {
            if ( !idMap[e.eDefId]) {
                idMap[e.eDefId] = {};
            }
            idMap[e.eDefId][e.dataSource.data.dataSourceId] = e.id;
        });
        ctx.idMap = idMap;
        const customEDef = model.isCustom ? model.customEntityDefs[eDefId] :
            EDataRegistry.instance.getEntityDefById( eDefId, model.defId );
        ctx.hasUsers = mappings.map( m => customEDef.dataItems[m.dataItemId])
            .some( di => di.type === DataType.USERS );
        return fromPromise( this.getEntities( customEDef, config, ctx )).pipe(
            switchMap(({ entities, errors }) => {
                if ( !ignoreMappingErrors && errors.length > 0 ) { // abort on errors
                    Logger.warning( 'aborting the import. validation errors: ', errors );
                    return throwError( new CSVImportValidationError( 'mappings error', errors ));
                } else if ( entities.length === 0 ) {
                    Logger.warning( 'aborting the import. no data to import' );
                    return throwError( new CSVImportValidationError( 'no data to import', errors ));
                }
                Logger.info( 'Importing entities...' );
                const observables = chunk( entities, this.batchSize )
                    .map( batch => defer(() => this.commandSvc.dispatch( EDataCommandEvent.importEntities, model.id, {
                        entities: batch,
                    })));
                return concat( ...observables ).pipe(
                    last(),
                    tap(() => Logger.info( 'Finished importing entities' )),
                    mapTo({ entities, errors }),
                );
            }),
        );
    }

    protected async getRows( startRow: number, endRow: number, spreadsheetId: string, sheetName: string ) {
        return this.nangoSvc.fetchPost({
            dataSource: DataSourceDomain.GoogleSheets,
            method: 'listRows',
            params: {
                startRow,
                endRow,
                spreadsheetId,
                sheetName,
            },
        });
    }

    protected async getEntities( customEDef: IEntityDef, config: any, context: any ) {
        let i = 2;
        let rows = [];
        let batch = [];
        do {
            batch = await this.getRows( i, i + 999, config.spreadsheetId, config.sheet.title );
            batch.forEach(( row, idx ) => row.RowNumber = i + idx );
            rows = rows.concat( batch );
            i = i + 1000;
        } while ( batch.length === 1000 );
        const { mappings, eDefId, ignoreMappingErrors } = config;
        if ( !customEDef.id ) {
            customEDef = { ...customEDef, id: eDefId };
        }
        if ( !context.idMap[eDefId]) {
            context.idMap[eDefId] = {};
        }
        const idMap = context.idMap[eDefId];
        const mapper = {};
        const baseCharCode = 'A'.charCodeAt( 0 );
        const mapperContext = {
            ignoreMappingErrors,
            model: context.model,
            entityIdGetter: null,
            userResolverFn: null,
        };
        if ( context.hasUsers ) {
            const users = await EntityImportHelper.instance
                .fetchUsers( rows, config.mappings, customEDef, this.userSearchService, this.pl );
            mapperContext.userResolverFn = getUserResolverFn( users );
        }
        for ( const mapping of mappings ) {
            const { columnIndex, dataItemId } = mapping;
            const charCode = columnIndex.charCodeAt( 0 ) - baseCharCode;
            if ( charCode < 0 || charCode > 25 ) {
                throw new Error( `invalid column. column index: ${columnIndex}` );
            }
            if ( !customEDef.dataItems[dataItemId]) {
                throw new Error( `Data item with id '${dataItemId}' not found` );
            }
            const dataItem = customEDef.dataItems[dataItemId];
            const { type: dataType, options } = dataItem as any;
            if ( dataType === DataType.LOOKUP ) {
                if ( options.eDefId !== eDefId && !context.idMap[options.eDefId]) {
                    return { entities: [], errors: [{
                        rowIndex: 0,
                        columnIndex,
                        parsedValue: null,
                        value: '',
                        error: {
                            message: 'Referenced object mappings not found. please import it first.',
                        },
                    }]};
                }
                mapper[dataItemId] = {
                    columnIndex,
                    ...EntityImportHelper.instance.getDataItemMapper( customEDef.dataItems[dataItemId] as any, {
                        ...mapperContext,
                        entityIdGetter: ( id, ctx ) => ctx.idMap[options.eDefId][id],
                    }),
                };
            } else {
                mapper[dataItemId] = {
                    columnIndex,
                    ...EntityImportHelper.instance.getDataItemMapper(
                        customEDef.dataItems[dataItemId] as any,
                        mapperContext,
                    ),
                };
            }
        }
        const entities: EntityModel[] = [];
        const errors: ICsvMappingError[] = [];
        const toBeResolved = [];
        for ( let index = 0; index < rows.length; index++ ) {
            const row = rows[index];
            const idValue = row[config.rowIdentifierField];
            if ( idValue === '' ) {
                Logger.warning( 'skipping row with empty id value', row );
                continue;
            }
            let entity: EntityModel | Proxied<EntityModel>;
            let isExistingEntity = false;
            if ( idMap[ idValue ]) {
                const entityId = idMap[idValue];
                if ( !context.existingEntities[entityId]) {
                    // ignoring duplicate data row.
                    Logger.warning( 'duplicate data row detected ', row, entityId );
                    continue;
                }
                isExistingEntity = true;
                entity = Sakota.create( context.existingEntities[entityId]);
            } else {
                entity = EntityImportHelper.instance.createEntity( customEDef, context );
                entity.dataSource = {
                    domain: DataSourceDomain.GoogleSheets,
                    data: {
                        dataSourceId: idValue,
                        rowNumber: row.RowNumber,
                    },
                };
            }
            let error: IValidationError = null;
            let allErrored = true;
            for ( const dataItemId in mapper ) {
                if ( mapper[dataItemId].resolver ) {
                    const val = row[mapper[dataItemId].columnIndex];
                    toBeResolved.push([
                        mapper[dataItemId].resolver( entity, val ),
                        { rowIndex: index, columnIndex: mapper[dataItemId].columnIndex, value: val },
                    ]);
                } else if ( mapper[dataItemId].parse ) {
                    entity.data[dataItemId] = mapper[dataItemId].parse( row[mapper[dataItemId].columnIndex]);
                } else {
                    entity.data[dataItemId] = row[mapper[dataItemId].columnIndex];
                }
                const validator: IDataItem<DataType> = mapper[dataItemId].validator;
                if ( error = validator.validate( entity.data[dataItemId])) { // if validation errors
                    errors.push({
                        rowIndex: index,
                        columnIndex: mapper[dataItemId].columnIndex,
                        parsedValue: entity.data[dataItemId],
                        value: row[mapper[dataItemId].columnIndex],
                        error,
                    });
                    if ( ignoreMappingErrors ) {
                        if ( isExistingEntity ) {
                            entity.data.__sakota__.reset( dataItemId );
                        } else {
                            delete entity.data[dataItemId];
                        }
                    } else {
                        // returning as no further processing is required.
                        return { entities, errors };
                    }
                } else {
                    allErrored = false;
                }
            }
            if ( !allErrored ) { // if at least one mapping is correct
                idMap[entity.dataSource.data.dataSourceId] = entity.id;
                entities.push( entity );
            }
        }
        const entById = {};
        entities.forEach( e => entById[e.id] = e );
        context.entities = entById;
        context.allEntities = { ...context.existingEntities, ...entById };
        toBeResolved.forEach(([ fn, ctx ]) => {
            try {
                fn( context );
            } catch ( e ) {
                errors.push({
                    rowIndex: ctx.rowIndex,
                    columnIndex: ctx.columnIndex,
                    parsedValue: null,
                    value: ctx.value,
                    error: {
                        message: e.message,
                    },
                });
            }
        });
        // after resolving relations there can be more entities added to the context.
        return { entities: Object.values( entById ), errors };
    }
}
