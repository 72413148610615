import { CommandInterfaces, Command, AbstractHttpCommand, AppConfig, RequestMethod } from 'flux-core';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';

/**
 * This command will authenticate the diagram password,
 * Then returns a token.
 *
 * @author Banujan<banujan@cinergix.com>
 * @since Apr-2023
 */
@Injectable()
@Command()
export class AuthenticateDiagramPassword extends AbstractHttpCommand {

    public static get implements(): Array<CommandInterfaces> {
        return [ 'IHttpCommand' ];
    }

    constructor() {
        super()/* istanbul ignore next */;
    }

    get httpMethod(): RequestMethod {
        return RequestMethod.Post;
    }

    get httpUrl(): string {
        const restApiBaseUrl = AppConfig.get( 'REST_API_BASE_URL' );
        return `${restApiBaseUrl}/1/1/diagram/authenticate-diagram-password`;
    }

    public prepareData(): void | Observable<any> {

        const auth = this.data.useCurrentUser ? ( window.gravity.client.getCookie()) : undefined;

        this.data = {
            auth,
            rid: this.resourceId,
            pld: { ...this.data },
        };
    }

    public execute (): boolean {
        return true;
    }

}

Object.defineProperty( AuthenticateDiagramPassword, 'name', {
    value: 'AuthenticateDiagramPassword',
});
