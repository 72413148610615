import { Component, ViewChild, ElementRef } from '@angular/core';
import { AppConfig, ModalController, PopupWindow, StateService } from 'flux-core';
import { Subscription } from 'rxjs';

@Component({
  selector: 'tooltip-video-modal',
  templateUrl: './tooltip-video-modal.cmp.html',
  styleUrls: [ './tooltip-video-modal.cmp.scss' ],
})
export class TooltipVideoModal extends PopupWindow {

    public isVideoPlaying = false;

    @ViewChild( 'tooltipVideo' ) tooltipVideo: ElementRef<HTMLVideoElement>;

    /**
     * base url for tooltip modal video
     */
    public videoPath: string;

    /**
     * Array with all the subs.
     */
    protected subs: Array<Subscription>;

    /**
     * The window overlay.
     */
    @ViewChild( 'window' ) protected container;

    /**
     * The the window element.
     */
    @ViewChild( 'windowInner' ) protected containerInner;

    constructor(
        protected modalController: ModalController,
        protected stateService: StateService<any, any>,
    ) {
            super()/* istanbul ignore next */;
            this.subs = [];
            this.stateService.set( 'ModalBlur', false );
            this.videoPath = AppConfig.get( 'TOOLTIP_STATIC_BASE_URL' ) + 'Onboarding Video.mp4';
    }

    public playOrPauseVideo(): void {
        if ( this.tooltipVideo && this.tooltipVideo.nativeElement ) {
            const video = this.tooltipVideo.nativeElement;

            if ( video.paused ) {
                video.play();
            } else {
                video.pause();
            }
        }
    }

    public onVideoPlay() {
        this.isVideoPlaying = true;
    }

    public onVideoPause() {
        this.isVideoPlaying = false;
    }

    /**
     * Closes the window after animation is complete.
     */
      public closeWindow() {
        if ( this.container && this.containerInner ) {
            const sub = this.hideWindow( this.container, this.containerInner ).subscribe({
                complete: () => {
                    this.modalController.hide();
                },
            });
            this.subs.push( sub );

            this.tooltipVideo.nativeElement.pause();
            this.isVideoPlaying = false;
        }
    }

}
