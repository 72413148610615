import { Injectable } from '@angular/core';
import { Command, CommandService, StateService } from 'flux-core';
import { AbstractDiagramChangeCommand } from './abstract-diagram-change-command.cmd';
import { DiagramChangeService } from '../../../base/diagram/diagram-change.svc';
import { Observable } from 'rxjs';
import { Sakota } from '@creately/sakota';
import { EDataModel } from '../../../base/edata/model/edata.mdl';
import { EDataCommandEvent } from '../../../base/edata/command/edata-command-event';

const IMAGE_DATAITEM = '_imageURLSource';

/**
 * This command is used to add uploadedd image urls to entities
 */
@Injectable()
@Command()
export class AddEntityImages extends AbstractDiagramChangeCommand  {

    /**
     * Command input data format
     */
    public data: {
        files: [{ name: string, url: string }],
        entities: any[],
        edataModel: EDataModel,
    };

    constructor(
        protected ds: DiagramChangeService,
        protected commandSvc: CommandService,
        protected state: StateService<any, any> ) {
        super( ds ) /* istanbul ignore next */;
    }

    public prepareData(): void | Observable<any> {
        const eDataModel = Sakota.create( this.data.edataModel );
        const files = Object.values( this.data.entities );
        const entities = Object.keys( this.data.entities );
        for ( let i = 0; i < this.data.files.length; i++ ) {
            const filename = this.data.files[ i ].name;

            const entityId = entities[ files.findIndex( file => file.name === filename ) ];

            const entity = eDataModel.entities[ entityId ];
            entity.data[ IMAGE_DATAITEM ] = this.data.files[ i ].url;
        }
        const modifier = eDataModel.__sakota__.getChanges();
        this.commandSvc.dispatch( EDataCommandEvent.applyModifierEData, eDataModel.id, { modifier });
        this.state.set( 'AcknowledgementMessage', { open: false });
    }

}

Object.defineProperty( AddEntityImages, 'name', {
    value: 'AddEntityImages',
});
