<div class="modal-window-container fx-center-all fx-cover" #window>
    <div class="modal-window-inner" #windowInner>
        <div class="modal-window-close move-right">
            <button
            class="btn-medium nu-btn-icon"
            (click)="closeWindow()">
                <svg class="nu-icon nu-ic-close"><use xlink:href="./assets/icons/symbol-defs.svg#nu-ic-close-gray"></use></svg>
            </button>
        </div>
        <video
            class="tooltip-video"
            width="848"
            height="477"
            [src]="videoPath"
            preload="auto"
            (play)="onVideoPlay()"
            (pause)="onVideoPause()"
            controls
            autoplay
            #tooltipVideo>
            <track kind="captions" src="./assets/tooltip-tour/onboarding/onboarding-video.vtt" srclang="en" label="English">
        </video>
        <div [class.fx-hidden]="isVideoPlaying" class="tooltip-video-play" (click)="playOrPauseVideo()">
            <svg class="nu-icon nu-ic-play">
                <use xlink:href="./assets/icons/symbol-defs.svg#nu-ic-play-blue"></use>
            </svg>
        </div>
        <button
        class="btn-primary btn-medium proceed-btn move-right"
        (click)="closeWindow()">Let's go</button>
    </div>
</div>
