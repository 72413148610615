import { PeopleLocator, IPeople, IPeopleSource } from './../../../base/ui/shape-data-editor/people-locator';
import { Tracker } from 'flux-core';
import { Random } from 'flux-core';
import { MatChipInputEvent } from '@angular/material/chips';
import { TranslateService } from '@ngx-translate/core';
import { CollabModel } from 'flux-diagram/models';
import { map, switchMap, take, startWith, filter } from 'rxjs/operators';
import { IDataItemUIControl } from './data-items-uic.i';
import { Subject, BehaviorSubject, Observable, EMPTY } from 'rxjs';
import { Component, ChangeDetectionStrategy, ElementRef, ViewChild, Input, OnInit, Output } from '@angular/core';
import { FormControl } from '@angular/forms';
import { COMMA, ENTER, SPACE, TAB } from '@angular/cdk/keycodes';
import { MatAutocomplete, MatAutocompleteSelectedEvent } from '@angular/material/autocomplete';
import { go } from 'fuzzysort';
import { uniqBy } from 'lodash';

/**
 * The email validation regex as per the RFC 5322
 */

export const EMAIL_VALIDATION_REGEX: RegExp = new RegExp ([ '^(([^<>()\\[\\]\\\\.,;:\\s@"]+(\\.[^<>()\\[\\]\\\\.,;:',
                                                            '\\s@"]+)*)|(".+"))@((\\[[0-9]{1,3}\\.[0-9]{1,3}\\.',
                                                            '[0-9]{1,3}\\.[0-9]{1,3}])|(([a-zA-Z\\-0-9]+\\.)+',
                                                            '[a-zA-Z]{2,}))$' ].join( '' ));


/**
 * A people field may be a 'responsibility' in the workflow of the card. You can create multiple
 * assignee type fields, that can be currently the active owners of the card, or people
 * who had finished their work on the card.
 */
export enum PeopleRoleType {
    Active = 'active',
    Next = 'next',
    Done = 'done',
}

export interface IRoleData {
    roleType?: PeopleRoleType;
    dueDate?: number;
    isComplete?: boolean;
    taskId?: string;
    estimate?: number;
    effort?: number;
}


/**
 * This is an input component which can be used to collect collabs ( or any other user by email )
 */
@Component({
    selector: 'people-picker-uic',
    template: `
        <mat-form-field class="users-list">
            <mat-label *ngIf="displayLabel || (usersSubject | async).length === 0">Assign members</mat-label>
            <mat-chip-list #chipList>
                <mat-chip
                    *ngFor="let user of (usersSubject | async)"
                    [selectable]="selectable"
                    [removable]="removable"
                    (removed)="remove(user)">
                    <user-image class="user-image-chip"
                      imageSize="xxsmall"
                      [hasImage]="user.hasImage"
                      [imagePath]="user.image"
                      [userOnlineStatus]="true">
                    </user-image>
                    <span class="user-name fx-ellipsis">{{user.fullName || user.email }}</span>
                    <mat-icon matChipRemove *ngIf="removable">close</mat-icon>
                </mat-chip>
                <input
                    #usersInput
                    [formControl]="usersCtrl"
                    [matChipInputFor]="chipList"
                    [matAutocomplete]="auto"
                    [matChipInputSeparatorKeyCodes]="separatorKeysCodes"
                    (matChipInputTokenEnd)="add($event)">
            </mat-chip-list>

            <mat-autocomplete #auto="matAutocomplete" (optionSelected)="selected($event)">
            <mat-option *ngFor="let user of filteredUsers | async" [value]="user">
              <div class="user-item" >
                <user-image class="user-image-list"
                  imageSize="xsmall"
                  [hasImage]="user.hasImage"
                  [imagePath]="user.image"
                  [userColor]="user.displayColor"
                  [userInitials]="user.initial"
                  [userRegistered]="user.isRegistered"
                  [userOnlineStatus]="true">
                </user-image>
                <div class="user-fullname fx-ellipsis">{{user.fullName ? user.fullName + '&nbsp;&nbsp;' : '' }}{{user.email ? user.email + '&nbsp;&nbsp;' : '' }} {{user.roleTitle}}</div>
              </div>
            </mat-option>
          </mat-autocomplete>
        </mat-form-field>
    `,
    styleUrls: [ './people-picker-uic.cmp.scss' ],
    changeDetection: ChangeDetectionStrategy.OnPush,
})

/**
 * UI control for SingleSelectCombo
 */
export class PeoplePickerUIC implements
    IDataItemUIControl<{ source: IPeopleSource, people: IPeople[], roleData?: IRoleData }>, OnInit {

    /**
     * For tracking purpose only.
     * This property is to identify where this component is being used.
     */
     @Input()
    public context?: string;

    @Input()
    data?: any;

    /**
     * A unique id for the input.
     * This must be set at all times.
     */
    public id: string;

    /**
     * This behavior subject is used to hold the users ( CollabModel ) array
     */
    public usersSubject: BehaviorSubject<IPeople[]>;

    /**
     * This subject will emit the current IPerson array when the input is focused out
     * or a particular item is deleted
     * Fields which are marked as 'assignee' typed, will also emit the taskType indicating
     * the current status of the task.
     */
    @Output()
    public change: Subject<{ source: IPeopleSource, people: IPeople[], roleData?: IRoleData }>;

    /**
     * This observable emits the filtered items, the items are filtered
     * according the typed text value.
     */
    public filteredUsers: Observable<CollabModel[]>;

    /**
     * Make the items selectable
     */
    @Input()
    public selectable = false;

    /**
     * Make the itmes removable
     */
    @Input()
    public removable = true;

    /**
     * Display item label
     */
    @Input()
    public displayLabel = true;

    /**
     * Bind keys to add a new item
     */
    public separatorKeysCodes: number[] = [ ENTER, COMMA, TAB, SPACE ];

    public usersCtrl = new FormControl();

    @ViewChild( 'auto',  { static: false })
    public matAutocomplete: MatAutocomplete;

    @ViewChild( 'usersInput', { static: false })
    public usersInput: ElementRef<HTMLInputElement>;

    /**
     * Behavour subject to keep people in the database/source/edatamodel
     */
    protected peopleData: BehaviorSubject<{ source: IPeopleSource, people: IPeople[], roleData?: IRoleData }>;

     /**
      * Avaible color pallet for people
      */
    protected AVAILABLE_COLORS = [
         '#EE0D0D',
         '#7C54C9',
         '#75A75F',
         '#3946B2',
         '#E6BA00',
         '#DA5E8E',
         '#779C9C',
         '#465A83',
         '#4DA037',
         '#FF5F5F',
         '#CA4CDD',
         '#1F8DBC',
         '#24B35A',
         '#F0219B',
         '#3CB0AC',
         '#FB8F65',
         '#8A8269',
         '#FCA200',
         '#006CFF',
         '#FF5100',
         '#00A6D5',
         '#FB316A',
         '#7F8839',
         '#75A810',
         '#693836',
         '#2C6CAC',
         '#A22A49',
         '#9821B7',
         '#1C8879',
         '#C3830B',
         '#B0B500',
         '#003BA7',
         '#B86E2D',
         '#A376FF',
         '#54772E',
     ];


    constructor(
        protected pl: PeopleLocator,
        protected translate: TranslateService ) {
            this.peopleData = new BehaviorSubject( null );
            this.usersSubject = new BehaviorSubject([]);
            this.change = new Subject();
            this.usersCtrl = new FormControl();
            this.filteredUsers = this.getFilteredUsers();
    }

    ngOnInit() {
        if ( this.data ) {
            this.setData( this.data );
        }
    }

    /**
     * Handler function for selecting items from the suggestions list
     */
    public selected( event: MatAutocompleteSelectedEvent ) {
        const user = event.option.value;
        const users = this.usersSubject.value;
        if ( !users.find( u => u.id === user.id )) {
            this.usersSubject.next( users.concat([ user ]));
            this.emitChanges();
        }
        this.usersInput.nativeElement.value = '';
        this.usersCtrl.setValue( null );
    }

    /**
     * To Push a new item to the users array ( usersSubject )
     */
    public add( event: MatChipInputEvent ) {
        const input = event.input;
        const value = event.value;
        const users = this.usersSubject.value;

        if ( this.isEmailValid( value )) {
            const mockCollab = new CollabModel( Random.peopleId());
            mockCollab.email = value;
            ( mockCollab as any ).source = 'email';
            users.push( mockCollab );
            this.usersSubject.next( users );
        }
        // Reset the input value
        if ( input ) {
            input.value = '';
        }
        this.usersCtrl.setValue( null );
    }

    /**
     * Remove the specified usedr and emits the "change" subject
     */
    public remove( user: CollabModel ): void {
        const users = this.usersSubject.value;
        this.usersSubject.next( users.filter( u => u.id !== user.id ));
        this.emitChanges();
    }

    /**
     * Map the current CollabModel array to IPerson opjects and emits the change subject
     */
    public emitChanges() {
        const users = this.usersSubject.value;
        // FIXME: Dont understand why people peopleData like assuming there can be only
        // one source, but it is not the case.need more info about this implementation.
        if ( this.peopleData.value && this.peopleData.value.source.id ) {
            this.change.next({
                source: { id: this.peopleData.value.source.id },
                // TO DO, this should happen only for fields that are 'assignee' typed.
                roleData: {
                    roleType: PeopleRoleType.Active,
                },
                people: users.map( u => ({ id: u.id, fullName: u.fullName, email: u.email,
                     image: u.image , color: this.getColor( u.fullName || u.email ), hasImage: !!u.image })),
            });
        }

        if ( this.context ) {
            Tracker.track( `${this.context}.people.change` );
        }
    }

    /**
     * Set data to the this UI component
     */
    public setData( data: any ) {
        if ( data ) {
            const value = data.value || { people: [], source: { id: 'collabs', name: 'People' }};
            this.pl.getAllPeople( '*' ).pipe( take( 1 )).subscribe( peopleSources => {
                // const source = peopleSources
                //     .find( s => s.source.id === value.source.id );
                // FIXME: Dont understand why people peopleData like assuming there can be only
                // one source, but it is not the case.need more info about this implementation.

                // UPDATE 14/Oct/2024
                // Considering all sources instead of one
                const source: {
                    source: IPeopleSource,
                    people: IPeople[],
                } = {
                    source: {
                        id: 'all',
                        name: 'all',
                    },
                    people: uniqBy([].concat( ...peopleSources.map( s => s.people )) , 'id' ),
                };

                this.peopleData.next( source );

                data.label = !data.label ? source.source.name : data.label;
                const d = value.people
                    .map( p => ( source.people && source.people.find( ppl => ppl.id === p.id )) || p );
                this.usersSubject.next( d );
            });
        }
        this.data = data;
    }
    /**
     * get colors base on string
     */
    public getColor( name: string ): string {
        let n = 1;
        for ( let i = 0; i < name.length; ++i ) {
            n += name.charCodeAt( i );
        }
        n = n % this.AVAILABLE_COLORS.length;
        return this.AVAILABLE_COLORS[n];
    }

    protected getFilteredUsers() {
        return this.usersCtrl.valueChanges.pipe(
            startWith( null ),
            switchMap( keystrokes =>
              this.getAllPeople( keystrokes ).pipe(
                switchMap( _ =>
                  this.getPeople().pipe(
                    map( users => ({ keystrokes, users })),
                  )),
                map(( v: any ) => v.keystrokes ? this._filter( v.keystrokes, v.users ) : v.users ),
              )),
        );
    }

    protected getAllPeople( searchQuery: string = '*' ): Observable<void> {
        const data = this.data ? this.data : {};
        if ( data && searchQuery ) {
            const value = data.value || { people: [], source: { id: 'collabs', name: 'People' }, extraSources: []};
            return this.pl.getAllPeople( searchQuery ).pipe(
                take( 1 ),
                map( peopleSources => {
                    const source = peopleSources
                        .find( s => s.source.id === value.source.id );
                    const extraSources = value.extraSources || [];
                    const extraPeople = [].concat( ...extraSources
                        .map( s => peopleSources.find( ps => ps.source.id === s.id )).map( s => s.people ));
                    if ( source ) {
                        this.peopleData.next({
                            ...source,
                            people: uniqBy( source.people.concat( extraPeople ) , 'id' ),
                        });
                    }
                }),
            );
        } else {
            return EMPTY;
        }
    }

    /**
     * Returns an observable which emits the array of diagram collabs only once.
     */
    protected getPeople(): Observable<IPeople[]> {
        // TODO: return people based on config, currently return diagram collabs
        return this.peopleData.pipe(
            filter( v => !!v ),
            map( d => d.people ),
            take( 1 ),
        );
    }

    /**
     * Return true is the email is valid.
     */
    protected isEmailValid( email: string ): boolean {
        return false; // FIXME:  temporarily disable Adding custom users by email address.
        // if ( !email ) {
        //     return false;
        // }
        // return email.match( EMAIL_VALIDATION_REGEX ) != null;
    }

    /**
     * Filter out the given objects by the given keyStroke
     */
    private _filter( keyStroke, objects ) {
        try {
            // FIXME: Some users does not have roleTitle, using blank value in those cases, this is breaking
            // Role Field in cases, show empty fields where instance of entity has role field and adding and removing
            // Casue the issue - MN
            if ( typeof keyStroke === 'string' ) {
                objects.forEach( o => o.roleTranslated = o.roleTitle ? this.translate.instant( o.roleTitle ) : '' );
                const results = go( keyStroke, objects, {
                    keys: [ 'fullName', 'email', 'roleTranslated' ],
                });
                return results.map( r => r.obj ) as any;
            }
        } catch ( error ) {
            // tslint:disable-next-line: no-console
            console.error( error );
        }
        return [];
    }

}

