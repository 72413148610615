/**
 * a list of tooltips to be displayed on nucleus
 */

import { TempAddLibsMenu } from '../../../../editor/ui/temp-add-libs-menu/temp-add-libs-menu.cmp';
import { TooltipAutomateState } from '../../../base-states';
import {
    ITooltipTourStep,
    KnownApplicationLayouts,
    TooltipPrerequisites,
} from '../tooltip-tour.interface';

export const tooltipData: { [id: string]: ITooltipTourStep } = {
    'new-fab-tooltip': {
        stepId: 'new-fab-tooltip',
        title: 'TOOLTIP_TOUR.STEPS.FAB_BUTTON.TITLE',
        description: 'TOOLTIP_TOUR.STEPS.FAB_BUTTON.DESCRIPTION',
        animationURL: 'FAB.mp4',
        preReqTooltips: [
            'shape-data',
        ],
        level: 1,
        bodyDisplacementOverride: -30,
        playOnce: true,
        preventOverlapping: [
            KnownApplicationLayouts.LEFTSIDEBAR,
            KnownApplicationLayouts.MODALWINDOW,
            KnownApplicationLayouts.USERMENU,
        ],
    },
    'fab': {
        stepId: 'fab',
        title: 'TOOLTIP_TOUR.STEPS.FAB.TITLE',
        description: 'TOOLTIP_TOUR.STEPS.FAB.DESCRIPTION',
        level: 1,
        learnMoreStep: 'fab-1.1',
        prerequisites: [
            TooltipPrerequisites.TOOLTIP_VIEWED,
        ],
        preReqTooltips: [
            'new-fab-tooltip',
        ],
        preventOverlapping: [
            KnownApplicationLayouts.MODALWINDOW,
            KnownApplicationLayouts.USERMENU,
            KnownApplicationLayouts.LEFTSIDEBAR,
        ],
    },
    'fab-1.1': {
        stepId: 'fab-1.1',
        title: 'TOOLTIP_TOUR.STEPS.FAB-1-1.TITLE',
        description: 'TOOLTIP_TOUR.STEPS.FAB-1-1.DESCRIPTION',
        level: 2,
        stateChanges: [
            { SelectedFloatingPanel: 'shapes' },
            { ToggleTemplatesModal: false },
        ],
        prevStepId: 'fab',
        nextStepId: 'fab-1.2',
        animationURL: 'Quick Tools.mp4',
        playOnce: true,
    },
    'fab-1.2': {
        stepId: 'fab-1.2',
        title: 'TOOLTIP_TOUR.STEPS.FAB-1-2.TITLE',
        description: 'TOOLTIP_TOUR.STEPS.FAB-1-2.DESCRIPTION',
        level: 2,
        stateChanges: [
            { SelectedFloatingPanel: 'templates' },
            { ToggleTemplatesModal: true },
        ],
        positionOverride: {
            top: -15,
            left: 20,
        },
        tooltipPositionOverride: {
            left: 200,
            top: 20,
        },
        prevStepId: 'fab-1.1',
        nextStepId: 'fab-1.3',
        animationURL: 'Templates.mp4',
    },
    'fab-1.3': {
        stepId: 'fab-1.3',
        title: 'TOOLTIP_TOUR.STEPS.FAB-1-3.TITLE',
        description: 'TOOLTIP_TOUR.STEPS.FAB-1-3.DESCRIPTION',
        level: 2,
        prevStepId: 'fab-1.2',
        nextStepId: 'fab-1.4',
        animationURL: '1.3 Frames.mp4',
        stateChanges: [
            { SelectedFloatingPanel: 'frames' },
            { ToggleTemplatesModal: false },
        ],
        positionOverride: {
            top: 20,
        },
        delay: 1000,

    },
    'fab-1.4': {
        stepId: 'fab-1.4',
        title: 'TOOLTIP_TOUR.STEPS.FAB-1-4.TITLE',
        description: 'TOOLTIP_TOUR.STEPS.FAB-1-4.DESCRIPTION',
        level: 2,
        prevStepId: 'fab-1.3',
        learnMoreStep: 'fab-1.4.1',
        animationURL: 'Add Shapes onto the Workspace.mp4',
        stateChanges: [{ SelectedFloatingPanel: 'shapes' }],
    },
    'fab-1.4.1': {
        stepId: 'fab-1.4.1',
        title: 'TOOLTIP_TOUR.STEPS.FAB-1-4-1.TITLE',
        description: 'TOOLTIP_TOUR.STEPS.FAB-1-4-1.DESCRIPTION',
        level: 3,
        stateChanges: [{ SelectedFloatingPanel: 'shapes' }],
        animationURL: 'Browse Shapes Library.mp4',
        nextStepId: 'fab-1.4.2',
        prevStepId: 'fab-1.4',
    },
    'fab-1.4.2': {
        stepId: 'fab-1.4.2',
        title: 'TOOLTIP_TOUR.STEPS.FAB-1-4-2.TITLE',
        description: 'TOOLTIP_TOUR.STEPS.FAB-1-4-2.DESCRIPTION',
        level: 3,
        animationURL: 'Customize Shape Panel.mp4',
        modal: {
            type: TempAddLibsMenu,
        },
        prevStepId: 'fab-1.4.1',
        nextStepId: 'fab-1.4.3',
        delay: 200,
    },
    'fab-1.4.3': {
        stepId: 'fab-1.4.3',
        title: 'TOOLTIP_TOUR.STEPS.FAB-1-4-3.TITLE',
        description: 'TOOLTIP_TOUR.STEPS.FAB-1-4-3.DESCRIPTION',
        level: 3,
        animationURL: 'Search shapes.mp4',
        prevStepId: 'fab-1.4.2',
    },
    'header': {
        stepId: 'header',
        title: 'TOOLTIP_TOUR.STEPS.HEADER.TITLE',
        description: 'TOOLTIP_TOUR.STEPS.HEADER.DESCRIPTION',
        level: 1,
        learnMoreStep: 'header-2.1',
        preReqTooltips: [
            'fab-1.4.3',
        ],
        delay: 1000,
    },
    'header-2.1': {
        stepId: 'header-2.1',
        title: 'TOOLTIP_TOUR.STEPS.HEADER-2-1.TITLE',
        description: 'TOOLTIP_TOUR.STEPS.HEADER-2-1.DESCRIPTION',
        tooltip: 'TOOLTIP_TOUR.STEPS.HEADER-2-1.TOOLTIP',
        level: 2,
        prevStepId: 'header',
        nextStepId: 'header-2.2',
        delay: 500,
    },
    'header-2.2': {
        stepId: 'header-2.2',
        title: 'TOOLTIP_TOUR.STEPS.HEADER-2-2.TITLE',
        description: 'TOOLTIP_TOUR.STEPS.HEADER-2-2.DESCRIPTION',
        level: 2,
        learnMoreStep: 'header-2.2.1',
        animationURL: '2.1 Workspace Preference.mp4',
        delay: 500,
    },
    'header-2.2.1': {
        stepId: 'header-2.2.1',
        title: 'TOOLTIP_TOUR.STEPS.HEADER-2-2-1.TITLE',
        description: 'TOOLTIP_TOUR.STEPS.HEADER-2-2-1.DESCRIPTION',
        level: 3,
        prevStepId: 'header-2.2',
        nextStepId: 'header-2.2.2',
        animationURL: '2.1.2 Workspace Status.mp4',
        stateChanges: [
            { OpenRightSidebar: 'diagraminfo' },
        ],
        delay: 500,
        left: true,
    },
    'header-2.2.2': {
        stepId: 'header-2.2.2',
        title: 'TOOLTIP_TOUR.STEPS.HEADER-2-2-2.TITLE',
        description: 'TOOLTIP_TOUR.STEPS.HEADER-2-2-2.DESCRIPTION',
        level: 3,
        animationURL: '2.1.3 Grids And Guides.mp4',
        stateChanges: [{ OpenRightSidebar: 'diagraminfo' }],
        delay: 0,
        left: true,
        prevStepId: 'header-2.2.1',
    },
    'shape-prop': {
        stepId: 'shape-prop',
        title: 'TOOLTIP_TOUR.STEPS.SHAPE_PROP.TITLE',
        description: 'TOOLTIP_TOUR.STEPS.SHAPE_PROP.DESCRIPTION',
        level: 1,
        animationURL: 'Shape Properties.mp4',
        skippable: true,
        stateChanges: [
            { OpenRightSidebar: 'shapePanel' },
        ],
        prerequisites: [
            TooltipPrerequisites.SHAPE_SELECTED,
        ],
        positionOverride: {
            top: -15,
            left: 20,
        },
        tooltipPositionOverride: {
            left: -250,
            top: 20,
        },
    },
    'shape-data': {
        stepId: 'shape-data',
        title: 'TOOLTIP_TOUR.STEPS.SHAPE_DATA.TITLE',
        description: 'TOOLTIP_TOUR.STEPS.SHAPE_DATA.DESCRIPTION',
        level: 1,
        animationURL: 'Notes.mp4',
        stateChanges: [
            { OpenRightSidebar: 'shapeData' },
            { LeftSidebarVisibility: false },
        ],
        prerequisites: [
            TooltipPrerequisites.SHAPE_SELECTED,
            TooltipPrerequisites.TOOLTIP_VIEWED,
        ],
        preReqTooltips: [
            'floating-tools',
        ],
        positionOverride: {
            top: -15,
            left: 20,
        },
        tooltipPositionOverride: {
            left: -530,
            top: 70,
        },
    },
    'left-sidebar': {
        stepId: 'left-sidebar',
        title: 'TOOLTIP_TOUR.STEPS.LSB.TITLE',
        description: 'TOOLTIP_TOUR.STEPS.LSB.DESCRIPTION',
        animationURL: 'LSB.mp4',
        level: 1,
        positionOverride: {
            top: 50,
            left: 300,
        },
        bodyDisplacementOverride: -50,
        playOnce: true,
    },
    'lsb-1': {
        stepId: 'lsb-1',
        title: 'TOOLTIP_TOUR.STEPS.LSB-1.TITLE',
        description: 'TOOLTIP_TOUR.STEPS.LSB-1.DESCRIPTION',
        level: 2,
        animationURL: '1.2 Folder Panel.mp4',
        positionOverride: {
            left: -10,
            top: 10,
        },
    },
    // Navigation Panel
    'lsb-2': {
        stepId: 'lsb-2',
        title: 'TOOLTIP_TOUR.STEPS.LSB-2.TITLE',
        description: 'TOOLTIP_TOUR.STEPS.LSB-2.DESCRIPTION',
        level: 1,
        positionOverride: {
            left: 0,
            top: 50,
        },
        prerequisites: [
            TooltipPrerequisites.LSB_IS_NAV,
        ],
        bodyDisplacementOverride: -50,
    },
    // Task Panel
    'lsb-3': {
        stepId: 'lsb-3',
        title: 'TOOLTIP_TOUR.STEPS.LSB-3.TITLE',
        description: 'TOOLTIP_TOUR.STEPS.LSB-3.DESCRIPTION',
        tooltip: 'TOOLTIP_TOUR.STEPS.LSB-3.TOOLTIP',
        animationURL: 'Access your Tasks.mp4',
        learnMoreStep: 'lsb-3.1',
        skippable: true,
        stateChanges: [
            { TooltipAutomateState: TooltipAutomateState.expandTask },
            { TooltipAutomateState: TooltipAutomateState.hideTaskOption },

        ],
        ripplePrequisites: [
            TooltipPrerequisites.LSB_IS_TASKS,
        ],
        level: 1,
        positionOverride: {
            left: 0,
            top: 100,
        },
        bodyDisplacementOverride: -50,
    },
    'lsb-3.1': {
        stepId: 'lsb-3.1',
        title: 'TOOLTIP_TOUR.STEPS.LSB-3-1.TITLE',
        description: 'TOOLTIP_TOUR.STEPS.LSB-3-1.DESCRIPTION',
        level: 3,
        animationURL: 'Set Task status.mp4',
        stateChanges: [
            { TooltipAutomateState: TooltipAutomateState.expandTask },
            { TooltipAutomateState: TooltipAutomateState.expandTaskGroup },
            { TooltipAutomateState: TooltipAutomateState.showTaskOption },
        ],
        prevStepId: 'lsb-3',
        nextStepId: 'lsb-3.2',
        prerequisites: [ TooltipPrerequisites.TASKS ],
        registerFirstAnchorOnly: true,

    },
    'lsb-3.2': {
        stepId: 'lsb-3.2',
        title: 'TOOLTIP_TOUR.STEPS.LSB-3-2.TITLE',
        description: 'TOOLTIP_TOUR.STEPS.LSB-3-2.DESCRIPTION',
        level: 3,
        animationURL: 'Extended task panel.mp4',
        prevStepId: 'lsb-3.1',
        stateChanges: [
            { TooltipAutomateState: TooltipAutomateState.expandTask },
            { TooltipAutomateState: TooltipAutomateState.hideTaskOption },
            { TooltipAutomateState: TooltipAutomateState.openTaskDetail },

        ],
        prerequisites: [ TooltipPrerequisites.TASKS ],
        positionOverride: {
            top: 100,
            left: 890,
        },
    },
    'data': {
        stepId: 'data',
        title: 'TOOLTIP_TOUR.STEPS.DATA.TITLE',
        description: 'TOOLTIP_TOUR.STEPS.DATA.DESCRIPTION',
        level: 1,
        animationURL: 'Bring Your Data onto Creately.mp4',
        learnMoreStep: 'data-1',
        prerequisites: [
            TooltipPrerequisites.SHAPE_SELECTED,
            TooltipPrerequisites.LSB_IS_DATA,

        ],
        skipIfPrerequisiteNoMet: false,
        positionOverride: {
            top: 50,
        },
        bodyDisplacementOverride: -50,
    },
    'data-1': {
        stepId: 'data-1',
        title: 'TOOLTIP_TOUR.STEPS.DATA-1.TITLE',
        description: 'TOOLTIP_TOUR.STEPS.DATA-1.DESCRIPTION',
        level: 2,
        animationURL: 'Create a Database.mp4',
        prevStepId: 'data',
        nextStepId: 'data-2',
        stateChanges: [
            { TooltipAutomateState: TooltipAutomateState.expandEData },
            { OpenRightSidebar: 'none' },
        ],
        delay: 500,
        positionOverride: {
            left: 100,
        },
        prerequisites: [
            TooltipPrerequisites.EDATA,
        ],
    },
    'data-2': {
        stepId: 'data-2',
        title: 'TOOLTIP_TOUR.STEPS.DATA-2.TITLE',
        description: 'TOOLTIP_TOUR.STEPS.DATA-2.DESCRIPTION',
        tooltip: 'TOOLTIP_TOUR.STEPS.DATA-2.TOOLTIP',
        level: 2,
        animationURL: 'Save your Customized Shape.mp4',
        prevStepId: 'data-1',
        nextStepId: 'data-3',
        stateChanges: [
            { TooltipAutomateState: TooltipAutomateState.collapseLSB },
            { OpenRightSidebar: 'shapeData' },
        ],
        left: true,
        delay: 1000,
        bodyDisplacementOverride: -50,
        tooltipPositionOverride: {
            left: -30,
            top: 60,
        },
    },
    'data-3': {
        stepId: 'data-3',
        title: 'TOOLTIP_TOUR.STEPS.DATA-3.TITLE',
        description: 'TOOLTIP_TOUR.STEPS.DATA-3.DESCRIPTION',
        tooltip: 'TOOLTIP_TOUR.STEPS.DATA-3.TOOLTIP',
        level: 2,
        animationURL: 'Create Data Instances.mp4',
        prevStepId: 'data-2',
        nextStepId: 'data-4',
        stateChanges: [
            { OpenRightSidebar: 'none' },
            { TooltipAutomateState: TooltipAutomateState.expandEData },
        ],
        delay: 500,
        positionOverride: {
            top: 20,
        },
        bodyDisplacementOverride: -20,
        useAnchorOf: 'data',
    },
    'data-4': {
        stepId: 'data-4',
        title: 'TOOLTIP_TOUR.STEPS.DATA-4.TITLE',
        description: 'TOOLTIP_TOUR.STEPS.DATA-4.DESCRIPTION',
        level: 2,
        animationURL: 'Import CSV.mp4',
        prevStepId: 'data-3',
        nextStepId: 'data-5',
        stateChanges: [
            { TooltipAutomateState: TooltipAutomateState.expandEData },
        ],
        positionOverride: {
            left: 40,
            top: -75,
        },
        useAnchorOf: 'data',
    },
    'data-5': {
        stepId: 'data-5',
        title: 'TOOLTIP_TOUR.STEPS.DATA-5.TITLE',
        description: 'TOOLTIP_TOUR.STEPS.DATA-5.DESCRIPTION',
        level: 3,
        prevStepId: 'data-4',
        nextStepId: 'data-6',
        stateChanges: [
            { TooltipAutomateState: TooltipAutomateState.expandEData },
        ],
        delay: 1000,
        useAnchorOf: 'data',

    },
    'data-6': {
        stepId: 'data-6',
        title: 'TOOLTIP_TOUR.STEPS.DATA-6.TITLE',
        description: 'TOOLTIP_TOUR.STEPS.DATA-6.DESCRIPTION',
        tooltip: 'TOOLTIP_TOUR.STEPS.DATA-6.TOOLTIP',
        level: 3,
        prevStepId: 'data-5',
        stateChanges: [
            { TooltipAutomateState: TooltipAutomateState.collapseLSB },
            { SelectedFloatingPanel: 'frames' },
        ],
        positionOverride: {
            top: 50,
        },
        bodyDisplacementOverride: -50,
    },

    'floating-tools': {
        stepId: 'floating-tools',
        title: 'TOOLTIP_TOUR.STEPS.FLOATING_TOOLS.TITLE',
        description: 'TOOLTIP_TOUR.STEPS.FLOATING_TOOLS.DESCRIPTION',
        tooltip: 'TOOLTIP_TOUR.STEPS.FLOATING_TOOLS.TOOLTIP',
        animationURL: 'FLOATING_TOOLS.mp4',
        level: 1,
        nextStepId: 'plus-create',
        stateChanges: [
            { TooltipAutomateState: TooltipAutomateState.hidePlusCreateTooltip },
        ],
        prerequisites: [
            TooltipPrerequisites.SHAPE_SELECTED,
        ],
        canvasElement: true,
        preventOverlapping: [
            KnownApplicationLayouts.HEADER,
            KnownApplicationLayouts.LEFTSIDEBAR,
            KnownApplicationLayouts.MODALWINDOW,
            KnownApplicationLayouts.RIGHTSIDEBAR,
            KnownApplicationLayouts.USERMENU,
            KnownApplicationLayouts.DIAGRAMTOOLBAR,
            KnownApplicationLayouts.FLOATINGPANEL,
            KnownApplicationLayouts.SHAPEPROPACTIONS,
        ],
    },
    'plus-create': {
        stepId: 'plus-create',
        title: 'TOOLTIP_TOUR.STEPS.PLUS_CREATE.TITLE',
        description: 'TOOLTIP_TOUR.STEPS.PLUS_CREATE.DESCRIPTION',
        level: 2,
        prevStepId: 'floating-tools',
        animationURL: 'PLUS_CREATE.mp4',
        stateChanges: [
            { TooltipAutomateState: TooltipAutomateState.showPlusCreateTooltip },
        ],
        prerequisites: [
            TooltipPrerequisites.SHAPE_SELECTED,
        ],
        canvasElement: true,
        preventOverlapping: [
            KnownApplicationLayouts.HEADER,
            KnownApplicationLayouts.LEFTSIDEBAR,
            KnownApplicationLayouts.MODALWINDOW,
            KnownApplicationLayouts.RIGHTSIDEBAR,
            KnownApplicationLayouts.USERMENU,
            KnownApplicationLayouts.DIAGRAMTOOLBAR,
            KnownApplicationLayouts.FLOATINGPANEL,
            KnownApplicationLayouts.SHAPEPROPACTIONS,
        ],
    },

    'onboarding-init': {
        stepId: 'onboarding-init',
        heading: 'TOOLTIP_TOUR.STEPS.ONBOARDING-INIT.HEADING',
        title: 'TOOLTIP_TOUR.STEPS.ONBOARDING-INIT.TITLE',
        description: 'TOOLTIP_TOUR.STEPS.ONBOARDING-INIT.DESCRIPTION',
        maybeLater: true,
        watchVideo: true,
        hideNext: true,
        startTourOnClose: 'onboarding',
        level: 0,
        animationURL: 'Onboarding Init.mp4',
        customWidth: 400,
        customHeight: 400,
        backdrop: false,
        customCssClass: 'onboarding-tour init',
        hasFooterSeparator: true,
    },
    // Dummy step to keep track whether user has clicked on watch video
    // on `onboarding-init` step
    'onboarding-video': {
        stepId: 'onboarding-video',
        title: '',
        description: '',
        level: 0,
    },
    'onboarding': {
        stepId: 'onboarding',
        title: 'TOOLTIP_TOUR.STEPS.ONBOARDING.TITLE',
        userSpecificTitle: true,
        description: 'TOOLTIP_TOUR.STEPS.ONBOARDING.DESCRIPTION',
        maybeLater: true,
        startTour: true,
        hideNext: true,
        level: 2,
        tooltipOnlyPositionOverride: {
            left: 100,
            top: 70,
        },
        nextStepId: 'onboarding-1',
        staticImageURL: './assets/tooltip-tour/onboarding/onboarding-welcome.png',
        delay: 2000,
        customWidth: 400,
        customHeight: 454,
        backdrop: false,
        arrowPlacement: {
            position: 'top',
        },
        customCssClass: 'onboarding-tour step-0',
        hideRipple: true,
        hideClose: true,
        preventMarkingStepAsViewedOnClose: true,
        storeViewedStatusInSessionStorageOnClose: true,
    },
    'onboarding-1': {
        stepId: 'onboarding-1',
        title: 'TOOLTIP_TOUR.STEPS.ONBOARDING-1.TITLE',
        description: 'TOOLTIP_TOUR.STEPS.ONBOARDING-1.DESCRIPTION',
        continueTour: true,
        hideNext: true,
        level: 1,
        positionOverride: {
            top: 28,
            left: -164,
        },
        tooltipOnlyPositionOverride: {
            left: 116,
            top: 56,
        },
        nextStepId: 'onboarding-2',
        animationURL: 'Onboarding - Header Title.mp4',
        delay: 1000,
        customWidth: 400,
        customHeight: 439,
        backdrop: false,
        stepCompleteReaction: true,
        showStepCount: true,
        showLineToTooltip: {
            startSocket: 'bottom',
            endSocket: 'left',
            x: '0%',
            y: '12%',
            lineType: 'grid',
        },
        customCssClass: 'onboarding-tour',
        preventRippleClick: true,
        preventMarkingStepAsViewedOnClose: true,
        hideRippleWhenInactive: true,
    },
    'onboarding-2': {
        stepId: 'onboarding-2',
        title: 'TOOLTIP_TOUR.STEPS.ONBOARDING-2.TITLE',
        description: 'TOOLTIP_TOUR.STEPS.ONBOARDING-2.DESCRIPTION',
        continueTour: true,
        hideNext: true,
        level: 1,
        tooltipOnlyPositionOverride: {
            left: 54,
            top: -34,
        },
        nextStepId: 'onboarding-3',
        animationURL: 'Onboarding - Fab.mp4',
        stateChanges: [{ SelectedFloatingPanel: 'shapes' }],
        customWidth: 400,
        customHeight: 418,
        backdrop: false,
        stepCompleteReaction: true,
        showLineToTooltip: {
            x: 0,
            y: '16.25%',
            lineType: 'straight',
        },
        customCssClass: 'onboarding-tour',
        preventRippleClick: true,
        preventMarkingStepAsViewedOnClose: true,
        hideRippleWhenInactive: true,
    },
    'onboarding-3': {
        stepId: 'onboarding-3',
        title: 'TOOLTIP_TOUR.STEPS.ONBOARDING-3.TITLE',
        description: 'TOOLTIP_TOUR.STEPS.ONBOARDING-3.DESCRIPTION',
        continueTour: true,
        hideNext: true,
        level: 1,
        tooltipOnlyPositionOverride: {
            left: -595,
            top: 70,
        },
        positionOverride: {
            top: 20,
        },
        nextStepId: 'onboarding-4',
        animationURL: 'Onboarding - Notes.mp4',
        customWidth: 400,
        customHeight: 418,
        backdrop: false,
        stepCompleteReaction: true,
        prerequisites: [
            TooltipPrerequisites.SHAPE_SELECTED,
        ],
        showLineToTooltip: {
            startSocket: 'left',
            endSocket: 'top',
            x: '92%',
            y: 0,
            lineType: 'grid',
        },
        customCssClass: 'onboarding-tour',
        preventRippleClick: true,
        preventMarkingStepAsViewedOnClose: true,
        hideRippleWhenInactive: true,
    },
    'onboarding-4': {
        stepId: 'onboarding-4',
        title: 'TOOLTIP_TOUR.STEPS.ONBOARDING-4.TITLE',
        description: 'TOOLTIP_TOUR.STEPS.ONBOARDING-4.DESCRIPTION',
        continueTour: true,
        hideNext: true,
        level: 1,
        tooltipOnlyPositionOverride: {
            left: -50,
            top: 65,
        },
        positionOverride: {
            top: 15,
            left: -32,
        },
        nextStepId: 'onboarding-5',
        animationURL: 'Onboarding - Share.mp4',
        customWidth: 400,
        customHeight: 439,
        backdrop: false,
        stepCompleteReaction: true,
        showLineToTooltip: {
            startSocket: 'bottom',
            endSocket: 'top',
            x: '9.45%',
            y: 0,
            lineType: 'straight',
        },
        customCssClass: 'onboarding-tour',
        preventRippleClick: true,
        preventMarkingStepAsViewedOnClose: true,
        hideRippleWhenInactive: true,
    },
    'onboarding-5': {
        stepId: 'onboarding-5',
        title: 'TOOLTIP_TOUR.STEPS.ONBOARDING-5.TITLE',
        description: 'TOOLTIP_TOUR.STEPS.ONBOARDING-5.DESCRIPTION',
        continueTour: true,
        hideNext: true,
        level: 1,
        positionOverride: {
            left: -39,
            top: -34,
        },
        tooltipOnlyPositionOverride: {
            left: -375,
            top: -437,
        },
        bodyDisplacementOverride: 281,
        nextStepId: 'onboarding-6',
        animationURL: 'Onboarding - Move And Zoom In Canvas.mp4',
        customWidth: 400,
        customHeight: 418,
        backdrop: false,
        stepCompleteReaction: true,
        preReqMethod: true,
        showLineToTooltip: {
            startSocket: 'top',
            endSocket: 'bottom',
            x: 363,
            y: '100%',
            lineType: 'straight',
        },
        customCssClass: 'onboarding-tour',
        preventRippleClick: true,
        preventMarkingStepAsViewedOnClose: true,
        hideRippleWhenInactive: true,
    },
    'onboarding-6': {
        stepId: 'onboarding-6',
        title: 'TOOLTIP_TOUR.STEPS.ONBOARDING-6.TITLE',
        description: 'TOOLTIP_TOUR.STEPS.ONBOARDING-6.DESCRIPTION',
        continueTour: true,
        hideNext: true,
        level: 1,
        tooltipOnlyPositionOverride: {
            left: 300,
            top: 104,
        },
        positionOverride: {
            top: 25,
        },
        animationURL: './assets/anim/onboarding-tour/Onboarding - Left Sidebar.mp4',
        customWidth: 400,
        customHeight: 418,
        backdrop: false,
        stepCompleteReaction: true,
        preReqMethod: true,
        showLineToTooltip: {
            startSocket: 'bottom',
            endSocket: 'top',
            x: '6%',
            y: 0,
            lineType: 'grid',
        },
        customCssClass: 'onboarding-tour',
        preventRippleClick: true,
        preventMarkingStepAsViewedOnClose: true,
        hideRippleWhenInactive: true,
    },
    'creately-viz': {
        stepId: 'creately-viz',
        title: 'TOOLTIP_TOUR.STEPS.CREATELY-VIZ.TITLE',
        description: 'TOOLTIP_TOUR.STEPS.CREATELY-VIZ.DESCRIPTION',
        upgradeText: 'TOOLTIP_TOUR.STEPS.CREATELY-VIZ.UPGRADE_TEXT',
        helpText: 'TOOLTIP_TOUR.STEPS.CREATELY-VIZ.HELP_TEXT',
        level: 0,
        customWidth: 450,
        customHeight: 422,
        nextStepId: 'creately-viz-1',
        animationImageURL: 'VIZ.gif',
        autoChangeInterval: 10000,
        preventMarkingViewedStepsInUI: true,
    },
    'creately-viz-1': {
        stepId: 'creately-viz-1',
        title: 'TOOLTIP_TOUR.STEPS.CREATELY-VIZ.TITLE',
        description: 'TOOLTIP_TOUR.STEPS.CREATELY-VIZ-1.DESCRIPTION',
        upgradeText: 'TOOLTIP_TOUR.STEPS.CREATELY-VIZ.UPGRADE_TEXT',
        helpText: 'TOOLTIP_TOUR.STEPS.CREATELY-VIZ.HELP_TEXT',
        level: 0,
        customWidth: 450,
        customHeight: 422,
        prevStepId: 'creately-viz',
        nextStepId: 'creately-viz-2',
        animationImageURL: 'VIZ-1.gif',
        autoChangeInterval: 10000,
    },
    'creately-viz-2': {
        stepId: 'creately-viz-2',
        title: 'TOOLTIP_TOUR.STEPS.CREATELY-VIZ.TITLE',
        description: 'TOOLTIP_TOUR.STEPS.CREATELY-VIZ-2.DESCRIPTION',
        upgradeText: 'TOOLTIP_TOUR.STEPS.CREATELY-VIZ.UPGRADE_TEXT',
        helpText: 'TOOLTIP_TOUR.STEPS.CREATELY-VIZ.HELP_TEXT',
        level: 0,
        customWidth: 450,
        customHeight: 422,
        prevStepId: 'creately-viz-1',
        nextStepId: 'creately-viz-3',
        animationImageURL: 'VIZ-2.gif',
        autoChangeInterval: 10000,
    },
    'creately-viz-3': {
        stepId: 'creately-viz-3',
        title: 'TOOLTIP_TOUR.STEPS.CREATELY-VIZ.TITLE',
        description: 'TOOLTIP_TOUR.STEPS.CREATELY-VIZ-3.DESCRIPTION',
        upgradeText: 'TOOLTIP_TOUR.STEPS.CREATELY-VIZ.UPGRADE_TEXT',
        helpText: 'TOOLTIP_TOUR.STEPS.CREATELY-VIZ.HELP_TEXT',
        level: 0,
        customWidth: 450,
        customHeight: 422,
        prevStepId: 'creately-viz-2',
        animationImageURL: 'VIZ-3.gif',
    },
};
