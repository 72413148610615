import { IRectangle, IPoint2D } from 'flux-definition';
import { Point } from './point';

export enum HorizontalAlignment {
    Left,
    Center,
    Right,
}

export enum VerticalAlignment {
    Top,
    Center,
    Bottom,
}

// tslint:disable-next-line:only-arrow-functions
export function alignH( rects: IRectangle[], align: HorizontalAlignment ): IPoint2D[] {
    if ( align === HorizontalAlignment.Left ) {
        const minX = Point.min( ...rects ).x;
        return rects.map( r => ({ x: minX, y: r.y }));
    }
    if ( align === HorizontalAlignment.Right ) {
        const maxX = Math.max( ...rects.map( r => r.x + r.width ));
        return rects.map( r => ({ x: maxX - r.width, y: r.y }));
    }
    if ( align === HorizontalAlignment.Center ) {
        const minX = Point.min( ...rects ).x;
        const maxX = Math.max( ...rects.map( r => r.x + r.width ));
        const midX = ( minX + maxX ) / 2;
        return rects.map( r => ({ x: midX - r.width / 2, y: r.y }));
    }
    throw new Error( 'invalid alignment' );
}

// tslint:disable-next-line:only-arrow-functions
export function alignV( rects: IRectangle[], align: VerticalAlignment ): IPoint2D[] {
    if ( align === VerticalAlignment.Top ) {
        const minY = Point.min( ...rects ).y;
        return rects.map( r => ({ x: r.x, y: minY }));
    }
    if ( align === VerticalAlignment.Bottom ) {
        const maxY = Math.max( ...rects.map( r => r.y + r.height ));
        return rects.map( r => ({ x: r.x, y: maxY - r.height }));
    }
    if ( align === VerticalAlignment.Center ) {
        const minY = Point.min( ...rects ).y;
        const maxY = Math.max( ...rects.map( r => r.y + r.height ));
        const midY = ( minY + maxY ) / 2;
        return rects.map( r => ({ x: r.x, y: midY - r.height / 2 }));
    }
    throw new Error( 'invalid alignment' );
}

// tslint:disable-next-line:only-arrow-functions
export function distributeH( rects: IRectangle[]): IPoint2D[] {
    const sortedShapes = [ ...rects ].sort(( a, b ) => a.x - b.x );
    const sortedByEdge = [ ...rects ].sort(( a, b ) => b.x + b.width - ( a.x + a.width ));

    const leftmost = sortedShapes[0];
    const rightmost = sortedByEdge[0];

    const totalWidth = rightmost.x + rightmost.width - leftmost.x;
    const totalShapesWidth = sortedShapes.reduce(( sum, shape ) => sum + shape.width, 0 );
    const numberOfGaps = sortedShapes.length - 1;
    let spacing = ( totalWidth - totalShapesWidth ) / numberOfGaps;
    let currentX = leftmost.x + leftmost.width;

    // Map to track new positions
    const newPositions: { rect: IRectangle; x: number; y: number }[] = [];

    if ( leftmost === rightmost ) {
        const adjustedTotalWidth = totalShapesWidth - leftmost.width;
        spacing = ( totalWidth - adjustedTotalWidth ) / sortedShapes.length;
        currentX = leftmost.x;

        for ( let i = 1; i < sortedShapes.length; i++ ) {
            const rect = sortedShapes[i];
            const newX = currentX + spacing;
            newPositions.push({ rect, x: newX, y: rect.y });
            currentX = newX + rect.width;
        }

        return rects.map( rect => {
            const position = newPositions.find( pos => pos.rect === rect );
            return position ? { x: position.x, y: position.y } : { x: rect.x, y: rect.y };
        });
    }

    for ( let i = 1; i < sortedShapes.length; i++ ) {
        if ( sortedShapes[i].x === rightmost.x && sortedShapes[i].width === rightmost.width ) {
            continue;
        }
        const rect = sortedShapes[i];
        const newX = currentX + spacing;
        newPositions.push({ rect, x: newX, y: rect.y });
        currentX = newX + rect.width;
    }

    return rects.map( rect => {
        const position = newPositions.find( pos => pos.rect === rect );
        return position ? { x: position.x, y: position.y } : { x: rect.x, y: rect.y };
    });
}

// tslint:disable-next-line:only-arrow-functions
export function distributeV( rects: IRectangle[]): IPoint2D[] {
    const sortedShapes = [ ...rects ].sort(( a, b ) => a.y - b.y );
    const sortedByEdge = [ ...rects ].sort(( a, b ) => b.y + b.height - ( a.y + a.height ));

    const topmost = sortedShapes[0];
    const bottommost = sortedByEdge[0];

    const totalWidth = bottommost.y + bottommost.height - topmost.y;
    const totalShapesWidth = sortedShapes.reduce(( sum, shape ) => sum + shape.height, 0 );
    const numberOfGaps = sortedShapes.length - 1;
    let spacing = ( totalWidth - totalShapesWidth ) / numberOfGaps;
    let currentY = topmost.y + topmost.height;

    // Map to track new positions
    const newPositions: { rect: IRectangle; x: number; y: number }[] = [];

    if ( topmost === bottommost ) {
        const adjustedTotalWidth = totalShapesWidth - topmost.height;
        spacing = ( totalWidth - adjustedTotalWidth ) / sortedShapes.length;
        currentY = topmost.y;

        for ( let i = 1; i < sortedShapes.length; i++ ) {
            const rect = sortedShapes[i];
            const newY = currentY + spacing;
            newPositions.push({ rect, x: rect.x, y: newY });
            currentY = newY + rect.height;
        }

        return rects.map( rect => {
            const position = newPositions.find( pos => pos.rect === rect );
            return position ? { x: position.x, y: position.y } : { x: rect.x, y: rect.y };
        });
    }

    for ( let i = 1; i < sortedShapes.length; i++ ) {
        if ( sortedShapes[i].y === bottommost.y && sortedShapes[i].height === bottommost.height ) {
            continue;
        }
        const rect = sortedShapes[i];
        const newY = currentY + spacing;
        newPositions.push({ rect, x: rect.x, y: newY });
        currentY = newY + rect.height;
    }

    return rects.map( rect => {
        const position = newPositions.find( pos => pos.rect === rect );
        return position ? { x: position.x, y: position.y } : { x: rect.x, y: rect.y };
    });
}
