import { TranslateService } from '@ngx-translate/core';
import { CollabModel } from 'flux-diagram';
import { CollabLocator } from './../../diagram/locator/collab-locator';
import { EntityModel } from '../../edata/model/entity.mdl';
import { values, uniqBy } from 'lodash';
import { EDataLocatorLocator } from '../../edata/locator/edata-locator-locator';
import { Injectable, Injector } from '@angular/core';
import { Observable, combineLatest, of } from 'rxjs';
import { filter, map, switchMap, take } from 'rxjs/operators';
import { DiagramLocatorLocator } from '../../diagram/locator/diagram-locator-locator';
import { TeamSettingManager, UserInfoModel, UserLocator } from 'flux-user';
import { ITypesenseObject } from '../../user/user-typesense.svc';

/**
 * Locator that fetches people from all the avialbel databases
 * e.g. workspace collabs
 * people from edata
 * organization people etc.
 */
@Injectable()
export class PeopleLocator {

    constructor(
        protected cl: CollabLocator,
        protected ell: EDataLocatorLocator,
        protected ll: DiagramLocatorLocator,
        protected translate: TranslateService,
        protected userLocator: UserLocator,
        protected injector: Injector,
        protected teamSettingManager: TeamSettingManager,
    ) {}

    /**
     * Returns all the people and corresponding datasource info
     */
    public getAllPeople( searchQuery: string, limit = -1 ): Observable<{ source: IPeopleSource, people: IPeople[] }[]> {
        if ( !this.teamSettingManager.check( 'ShowPeoplePickerSuggestion' )) {
            return of([]);
        }
        return combineLatest([
            this.getPeopleFromEdata(),
            this.getAllCollabs( searchQuery, limit ),
        ]).pipe(
            map(([ edataPeople, allCollabs ]) => [
                ...edataPeople,
                ...allCollabs,
            ]),
        );
    }

    public getCollabs(): Observable<{ source: IPeopleSource, people: IPeople[] }> {
        return combineLatest([
            this.getWorkspaceCollabs(),
            this.getProjectCollabs(),
        ]).pipe(
            map(([ workspaceCollabs, projectCollabs ]) => {
                const source = {
                    id: 'collabs',
                    name: this.translate.instant( 'SHAPE_DATA.DATA_ITEMS.PEOPLE.LABEL' ),
                };
                return {
                    source,
                    people: uniqBy(
                        [ ...workspaceCollabs[0].people, ...projectCollabs[0].people ], 'email' ),
                };
            }),
        );
    }

    /**
     * Emits people fron edata
     * @returns {
     *      source: IPeopleSource,
     *      people: IPeople[],
     * }
     */
    protected getPeopleFromEdata(): Observable<{ source: IPeopleSource, people: IPeople[] }[]> {
        return this.ell.currentEDataModelsOnce().pipe( map(
            dbs => {
                const data = dbs.map( db => {
                    let peopleProps = [ 'people' ];
                    // if ( db.isCustom ) {
                    Object.values( db.customEntityDefs ).forEach( entityDef => {
                        const peopleFields = Object.values( entityDef.dataItems )
                            .filter( di => di.type === 'people' || di.type === 'users' );
                        peopleProps = peopleProps.concat( peopleFields.map(( pf: any ) => pf.id ));
                    });
                    // }
                    const peopleEntities: EntityModel[] = values( db.entities )
                        .filter(( entity: EntityModel ) => {
                            if ( entity.data ) {
                                return peopleProps.some( prop => {
                                    const peopleObject = entity.data[prop];
                                    if ( peopleObject && peopleObject.people && peopleObject.people.length > 0 ) {
                                        return true;
                                    }
                                });
                            }
                            return false;
                        });
                    if ( peopleEntities && peopleEntities.length > 0 ) {
                        const eDataPeoples = [];
                        peopleEntities.forEach( entity => {
                            peopleProps.forEach( prop => {
                                if ( entity.data[prop] && entity.data[prop].people ) {
                                    eDataPeoples.push( ...entity.data[prop].people );
                                }
                            });
                        });
                        const peopleVal = peopleProps.map( prop => peopleEntities[0].data[prop]).find( val => !!val );
                        const source = peopleVal?.source || { id: 'collabs' };
                        const filteredEdataPeoples = uniqBy( eDataPeoples , 'id' );
                        return {
                            source: source.id === 'collabs' ? {
                                id: db.id,
                                name: db.name,
                            } : {
                                id: source.id || db.id,
                                name: source.name || db.name,
                            },
                            people: filteredEdataPeoples,
                        };
                    }
                }).filter( val => !!val );
                if ( data && data.length > 0 ) {
                    return data;
                }
                return [];
            },
        ));
    }

    /**
     * Emits both workspace and project collabs
     */
    protected getAllCollabs(
        searchQuery: string, limit = -1 ): Observable<{ source: IPeopleSource, people: IPeople[] }[]> {
        return combineLatest([
            this.getWorkspaceCollabs(),
            this.getProjectCollabs(),
            limit === -1 ?
                this.getPeopleFromTeam( searchQuery ) : this.getPeopleFromTeam( searchQuery, { per_page: limit }),
        ]).pipe(
            map(([ workspaceCollabs, projectCollabs, teamCollabs ]) => {
                const source = {
                    id: 'collabs',
                    name: this.translate.instant( 'SHAPE_DATA.DATA_ITEMS.PEOPLE.LABEL' ),
                };
                return [{
                    source,
                    people: uniqBy(
                        [ ...workspaceCollabs[0].people, ...projectCollabs[0].people, ...teamCollabs[0].people ], 'email' ),
                }];
            }),
        );
    }

    /**
     * Emits team users models
     */
    protected getPeopleFromTeam(
        searchQuery: string, options?: any ): Observable<{ source: IPeopleSource, people: IPeople[] }[]> {
        return this.userLocator.getUserData().pipe(
          filter( userModel => !!userModel ),
          switchMap( usr => {
            const source = {
              id: 'teams',
              name: this.translate.instant( 'SHAPE_DATA.DATA_ITEMS.PEOPLE.LABEL' ),
            };

            if ( usr.team ) {
              return this.teamUserSearch( searchQuery, options ).pipe(
                map( members => [{
                  source,
                  people: members,
                }]),
              );
            } else {
              return of([]);
            }
          }),
        );
    }

    protected teamUserSearch( searchQuery: string, options?: any ): Observable<any> {
        const userTypesenseService = this.injector.get( 'UserTypesenseService' );
        return userTypesenseService.teamUserSearch( searchQuery, options || {}).pipe(
            map(( data: ITypesenseObject[]) => data
                .map( user =>
                    ({
                        id : user.uid,
                        email: user.email,
                        fullName: `${user.first_name} ${user.last_name}`,
                    }),
            )),
        );
    }

    /**
     * Emits workspace collabs
     */
    protected getWorkspaceCollabs(): Observable<{ source: IPeopleSource, people: IPeople[] }[]> {
        const source = {
            id: 'workspace-collabs',
            name: this.translate.instant( 'SHAPE_DATA.DATA_ITEMS.PEOPLE.LABEL' ),
        };
        return this.cl.getCollabs().pipe(
            take( 1 ),
            map(( collabs: CollabModel[]) => ([{
                source,
                people: collabs.map( c => this.collabToPeople( c )),
            }])),
        );
    }

    /**
     * Emits project collabs
     */
    protected getProjectCollabs() {
        const source = {
            id: 'project-collabs',
            name: this.translate.instant( 'SHAPE_DATA.DATA_ITEMS.PEOPLE.LABEL' ),
        };
        return this.cl.getProjectCollabs().pipe(
            take( 1 ),
            map(( collabs: CollabModel[]) => ([{
                source,
                people: ( collabs || []).map( c => this.collabToPeople( c )),
            }])),
        );
    }

    protected collabToPeople( collab: CollabModel ): IPeople {
        return {
            id: collab.id,
            fullName: collab.fullName,
            email: collab.email,
            hasImage: collab.hasImage,
            image: collab.image,
        }; // nothing to map
    }

    protected teamToPeople( teamUser: UserInfoModel ): IPeople {
        return {
            id: teamUser.id,
            fullName: teamUser.fullName,
            email: teamUser.email,
            hasImage: teamUser.hasImage,
            image: teamUser.image,
        };
    }
}

export interface IPeopleSource {
    id: string;
    name?: string;
    // ....This can have other EdataModel (source) properties
}

export interface IPeople {
    id: string;
    image?: string;
    fullName?: string;
    email?: string;
    hasImage?: boolean;
}
