import { ViewChild, Input, Inject } from '@angular/core';
import { ChangeDetectionStrategy, Component, OnDestroy, OnInit } from '@angular/core';
import { ModalController, PopupWindow, PostMessageAPI, AppConfig, Tracker, StateService } from 'flux-core';
import { Subscription, BehaviorSubject } from 'rxjs';
import { TranslateService } from '@ngx-translate/core';
import { IDialogBoxData } from 'flux-definition';
import { supportedTranslateCode } from '../auth/onboarding-window.cmp';
import { PlanPermManager } from '../../permission/plan-perm-manager';


/**
 * An enum containing the available pages on the upgrade dialog.
 */
export enum UpgradeDialogType {
    Export = 'export',
    CreateWorkspace = 'create-workspace',
    Share = 'share',
    ItemLimit = 'items-limit',
    PremiumShape = 'premium-shapes',
    PremiumShapelibraries = 'premium-shape-libraries',
    PremiumToolkits = 'premium-toolkits',
    UnlimitedImport = 'unlimited-imports',
    PremiumTemplate = 'premium-templates',
    CreateFolder = 'create-folder',
    PasswordProtectedSharing = 'password-protected-sharing',
    AiTemplates = 'ai-templates',
    AutomatedCharts = 'automated-charts',
}

/**
 * Post message receive event types for upgrade dialog.
 */
export enum UpgradeDialogReceiveEvent {
    Close = 'phoenix:upgradeDialogClose',
    Upgrade = 'phoenix:upgradeDialogUpgradeClick',
    Action = 'phoenix:upgradeDialogContinueClick',
    SizeChange = 'phoenix:upgradeDialogSizeChange',
}

/**
 * An enum containing the available error message banner mapping.
 */
export enum UpgradeDialogErrorMessage {
    limitedToPubliclyViewableWorkspaces = 1, // Your plan is limited to publicly viewable workspaces
    limitedTeamSharing = 2, // Your plan is limited to team wide sharing only.
}

/**
 * Upgrate dialog window. This will handle the popup,
 * And show the benifits of paid plan.
 *
 * @author  Sajeeva
 * @since   2022-05-12
 */

@Component({
    templateUrl: 'upgrade-dialog-window.cmp.html',
    selector: 'upgrade-dialog-window',
    styleUrls: [ './upgrade-dialog-window.scss' ],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class UpgradeDialogWindow extends PopupWindow implements OnDestroy, OnInit {


    /**
     * Data used to display information on the window.
     */
    @Input() public dialogData: IDialogBoxData;

    /**
     * Custom class can be binded so extra styling
     */
    @Input() public customClass?: string;

    /**
     * Allow user to close the dialog box
     */
    public allowCloseWindow = true;

    /**
     * An array containing all subscriptions.
     */
    protected subs: Array<Subscription>;

    /**
     * This holds the value of isDemoUser function.
     * True - If the user is a demo user.
     */
    protected isDemoUser: BehaviorSubject<boolean>;

    /**
     * The wrapper contaner.
     */
    @ViewChild( 'window', { static: true })
    protected window;

    /**
     * The file exported window container.
     */
    @ViewChild( 'container', { static: true })
    protected container;

    constructor(
        @Inject( StateService ) protected state: StateService<any, any>,
        protected modalController: ModalController,
        protected translate: TranslateService,
        protected postMessage: PostMessageAPI,
        protected planPermManager: PlanPermManager,
    ) {
        super();
        this.subs = [];
        this.isDemoUser = new BehaviorSubject( false );
    }

    /**
     * This returns the IFrame url according to type
     */
    public get upgradeDialogUrl(): string {
        let siteUrl = AppConfig.get( 'SITE_URL' );
        let dialogUrl;
        if ( supportedTranslateCode.includes( this.translate.currentLang )) {
            siteUrl = `${AppConfig.get( 'SITE_URL' )}${this.translate.currentLang}/`;
        }
        switch ( this.dialogData.iframeType ) {
            case UpgradeDialogType.Export:
                dialogUrl = siteUrl + 'upgrade-dialog/export';
                break;
            case UpgradeDialogType.CreateWorkspace:
                dialogUrl = siteUrl + 'upgrade-dialog/create-workspace';
                break;
            case UpgradeDialogType.Share:
                dialogUrl = siteUrl + 'upgrade-dialog/share';
                break;
            case UpgradeDialogType.ItemLimit:
                dialogUrl = siteUrl + 'upgrade-dialog/items-limit';
                break;
            case UpgradeDialogType.PremiumShape:
                dialogUrl = siteUrl + 'upgrade-dialog/premium-shapes';
                break;
            case UpgradeDialogType.PremiumShapelibraries:
                dialogUrl = siteUrl + 'upgrade-dialog/premium-shape-libraries';
                break;
            case UpgradeDialogType.PremiumToolkits:
                dialogUrl = siteUrl + 'upgrade-dialog/premium-toolkits';
                break;
            case UpgradeDialogType.UnlimitedImport:
                dialogUrl = siteUrl + 'upgrade-dialog/unlimited-imports';
                break;
            case UpgradeDialogType.PremiumTemplate:
                dialogUrl = siteUrl + 'upgrade-dialog/premium-templates';
                break;
            case UpgradeDialogType.CreateFolder:
                dialogUrl = siteUrl + 'upgrade-dialog/create-folder';
                break;
            case UpgradeDialogType.PasswordProtectedSharing:
                dialogUrl = siteUrl + 'upgrade-dialog/password-protected-sharing';
                break;
            case UpgradeDialogType.AiTemplates:
                dialogUrl = siteUrl + 'upgrade-dialog/ai-templates';
                break;
            case UpgradeDialogType.AutomatedCharts:
                dialogUrl = siteUrl + 'upgrade-dialog/automated-charts';
                break;
            default:
                dialogUrl = siteUrl + 'upgrade-dialog/generic';
                break;
        }
        dialogUrl = dialogUrl + '/?location=' + this.dialogData.id;
        const upgradeButton = ( this.dialogData.buttons || []).find( value => value.type === 'action' );
        if ( upgradeButton && upgradeButton.visibility === 'disabled' ) {
            dialogUrl = dialogUrl + '&secondary=disabled';
        }
        if ( upgradeButton && upgradeButton.visibility === 'hidden' ) {
            dialogUrl = dialogUrl + '&secondary=hidden';
        }
        if ( this.dialogData.descriptionParams && ( this.dialogData.descriptionParams as any ).errorMessage ) {
            const errorMessage = ( this.dialogData.descriptionParams as any ).errorMessage;
            dialogUrl = dialogUrl + '&errorMessage=' + errorMessage;
        }
        if ( this.hasIntegrationContext( 'msteams', true )) {
            dialogUrl = dialogUrl + '&integration=msteams';
        }
        if ( this.dialogData.preventClosing ) {
            this.allowCloseWindow = false;
            dialogUrl = dialogUrl + '&close=disabled';
        }
        return dialogUrl;
    }

    public ngOnInit(): void {
        Tracker.track( 'conversion.dialog.upgrade.load', {
            value1: this.dialogData.id, value1Type: 'dialogId',
            value2: this.dialogData.iframeType ?? 'generic', value2Type: 'upgrade type',
        });

        this.subs.push(
            this.showWindow( this.container, this.window ).subscribe(),
            this.postMessage.recv().subscribe( message => this.handleIncomingMessages( message )),
            this.planPermManager.isDemoUser().subscribe( val => this.isDemoUser.next( val )),
        );
    }

    /**
     * close the IFrame
     */
    public closeWindow() {
        Tracker.track( 'conversion.dialog.upgrade.close' );

        const upgradeDialogType = this.dialogData.iframeType;

        const sub = this.hideWindow( this.container, this.window ).subscribe({
            complete: () => {
                this.modalController.hide();
            },
        });
        this.subs.push( sub );

        // Set ai upgrade dialog cookie `closed` value to true
        // if user closes upgrade dialog
        const aiUpgradeDialogCookie = 'ai_upgrade_dialog';

        if ( this.getCookie( aiUpgradeDialogCookie )) {
            const cookieValue = this.getCookie( aiUpgradeDialogCookie );

            let properties = {};
            if ( cookieValue ) {
                properties = JSON.parse( cookieValue );
            }

            Object.assign( properties, { closed: true });

            document.cookie = aiUpgradeDialogCookie + '=' + JSON.stringify( properties ) + ';'
            + `domain=${AppConfig.get( 'COOKIE_DOMAIN' )}` + ';path=/';
        }

        // Set automated charts dialog cookie `closed` value to true
        // if the dialog type that is closed is `automated-charts`
        if ( upgradeDialogType === UpgradeDialogType.AutomatedCharts ) {
            this.updateCookieProperty( 'automated_charts_dialog', { closed: true });
        }
    }

    /**
     * Returns the inner container of the modal window.
     */
    public getModalWindowInnerComponent(): Element {
        if ( this.state.get( 'ModalWindow' ).show ) {
            return document.getElementsByClassName( 'modal-window-inner' )[0];
        }
    }

    public ngOnDestroy(): void {
        while ( this.subs.length > 0 ) {
            this.subs.pop().unsubscribe();
        }
    }

    /**
     * Handle incoming post messages from upgrade dialog.
     */
    private handleIncomingMessages( message: any ): void {
        if ( !message || typeof message !== 'object' ) {
            return;
        }
        const { event } = message as any;
        if ( event === UpgradeDialogReceiveEvent.Close ) {
            this.handleClose();
        }
        if ( event === UpgradeDialogReceiveEvent.Upgrade ) {
            this.handleUpgrade();
        }
        if ( event === UpgradeDialogReceiveEvent.Action ) {
            this.handleAction();
        }
        if ( event === UpgradeDialogReceiveEvent.SizeChange ) {
            this.handleSizeChange( message.data );
        }
    }

    /**
     * close handler
     */
    private handleClose() {
        this.closeWindow();
        if ( this.dialogData.closeClickHandler ) {
            this.dialogData.closeClickHandler();
        }
    }


    /**
     * Upgrade Handler
     * FIXME: We will pass an additional button with
     * relevant ModalController when displaying
     * UpgradeDialogWindow to have legacy compatbility
     * to support how it worked earlier. However,
     * UpgradeDialogWindow will always have 'Upgrade'
     * button (regardless we pass this or not) and
     * its action button will dictate additional behavior
     * and visibility for UpgradeDialogWindow's flow
     */
    private handleUpgrade() {
        const upgradeButton = ( this.dialogData.buttons || []).find( value => value.type === 'upgrade' );
        if ( upgradeButton && this.hasIntegrationContext( 'msteams', true )) {
            upgradeButton.clickHandler();
            this.postMessage.sendToParent( 'feature:upgrade', {});
        } else if ( this.isDemoUser.value ) {
            this.closeWindow();
            this.state.set( 'showAuthDialog', true );
        } else if ( upgradeButton ) {
            upgradeButton.clickHandler();
        }
    }

    /**
     * Action Handler
     */
    private handleAction() {
        this.closeWindow();
        const actionButton = ( this.dialogData.buttons || []).find( value => value.type === 'action' );
        if ( actionButton ) {
            actionButton.clickHandler();
        }
    }

    /**
     * Size Change Handler
     */
    private handleSizeChange( data: any ) {
        let styles = '';

        if ( data.width ) {
            styles += `width: ${data.width}px; `;
        }

        if ( data.height ) {
            styles += `height: ${data.height}px; `;
        }

        this.getModalWindowInnerComponent().setAttribute( 'style', styles );
    }

    /**
     * Check to see if dialog box is shown inside
     * 3rd party integration context. This allows
     * wrapping app aka. integration to prompt
     * things like in-app purchases
     */
    private hasIntegrationContext( appEnv: 'none' | 'confluence' | 'msteams', isEmbedded: boolean ) {
        return ( this.dialogData.integrationContext
            && this.dialogData.integrationContext.embedded === isEmbedded
            && this.dialogData.integrationContext.environment === appEnv
        );
    }

    /**
     * Return cookie value if exists, otherwise return false
     * @param {string} name cookie name
     */
    private getCookie( name: string ) {
        const match = document.cookie.match( new RegExp( '(^| )' + name + '=([^;]+)' ));
        if ( match ) {
            return match[2];
        } else {
            return false;
        }
    }

    /**
     * Updates a specified cookie with given properties.
     */
    private updateCookieProperty( cookieName: string,
                                  propertiesToUpdate: Record<string, unknown> ): void {
        if ( this.getCookie( cookieName )) {
            const cookieValue = this.getCookie( cookieName );
            const properties = cookieValue ? JSON.parse( cookieValue ) : {};
            Object.assign( properties, propertiesToUpdate );

            document.cookie = `${cookieName}=${JSON.stringify( properties )};`
                + `domain=${AppConfig.get( 'COOKIE_DOMAIN' )};path=/`;
        }
    }
}
