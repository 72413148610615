import { AfterViewInit, Directive, Inject, OnDestroy, Output } from '@angular/core';
import { MouseControlState } from '../../../../base/base-states';
import { EntityModel } from '../../../../base/edata/model/entity.mdl';
import { StateService, CommandService, Tracker, Rectangle } from 'flux-core';
import { ICollapsibleMenuItem } from 'flux-core/src/ui';
import { IShapeDefinition } from 'flux-definition';
import { isEqual } from 'lodash';
import { EMPTY, merge as rxMerge, Observable, Subject, Subscription, timer } from 'rxjs';
import { mapTo, switchMap, tap } from 'rxjs/operators';
import { BaseDiagramCommandEvent } from '../../../../base/diagram/command/base-diagram-command-event';
import { ShapeManageService } from '../../../feature/shape-manage.svc';
import { TooltipTourService } from 'apps/nucleus/src/base/ui/tooltip-tour/tooltip-tour.svc';

/**
 * This is a library panel layout component which
 * will be inserted inside a collapsible menu item.
 *
 * @author gobiga
 * @since 2017-11-17
 */

@Directive()
export class LibraryPanel implements ICollapsibleMenuItem, AfterViewInit, OnDestroy {

    /**
     * Displayable name for the library
     */
    public title: string;

    public trackingInfo: { location?: string };

    /**
     * Icon that needs to be displayed for the
     * specific library.
     */
    public icon: Observable<any>;

    /**
     * Items that needs to be added to the panel.
     */
    public items: Observable<Array<ILibraryItem>>;

    public timeout: number = 10;

    public isMouseHover: boolean = false;

    /**
     * Items that needs to be added to the panel.
     */
    public moreItems: Observable<Array<ILibraryItem>>;

    protected subs: Subscription[];

    /**
     * Id of the panel
     */
    protected _id: string;

    /**
     * Stream to handle mouse enter with item and event data
     */
    @Output()
    private mouseEnterStream: Subject<{item: ILibraryItem, event: Event}>
        = new Subject<{item: ILibraryItem, event: Event}>();

    /**
     * Stream to handle mouse leave with event data
     */
    @Output()
    private mouseLeaveStream: Subject<Event> = new Subject<Event>();

    constructor(
        @Inject( StateService ) protected state: StateService<any, any>,
        protected commandService: CommandService,
        protected shapeManageService: ShapeManageService,
        protected tourSvc?: TooltipTourService,
    ) {
        this.subs = [];
        this.trackingInfo = {};
    }

     public get id(): string {
         return this._id;
     }

     public set id( id: string ) {
         this._id = id;
     }

    /**
     * Mouse enter function
     * Sets the mouse enter stream to emit values
     * @param item
     * @param event
     */
    public handleMouseEnter( item: ILibraryItem, event: Event ) {
        this.mouseEnterStream.next({ item: item, event: event });
    }

    /**
     * Mouse Leave function
     * Sets the mouse leave stream to emit values
     * @param event
     */
    public handleMouseLeave( event: Event ) {
        this.mouseLeaveStream.next( event );
    }

    /**
     * Listens to mouse interactions and calls relevant methods when mouse events occur.
     */
    public ngAfterViewInit() {
        this.subs.push(
            rxMerge(
            this.mouseEnterStream,
            this.mouseLeaveStream.pipe( mapTo( false )),
            ).pipe(
                switchMap( mouseEnter => mouseEnter ? this.mouseEnter( mouseEnter ) : this.handleMouseOut()),
            ).subscribe(),
        );
    }

    public ngOnDestroy() {
        while ( this.subs.length ) {
            this.subs.pop().unsubscribe();
        }
    }

    /**
     * Set state of library preview with relevant data when mouse is on the library tile
     * @param mouseEnterParams
     */
    public mouseEnter( mouseEnterParams: any ): Observable<unknown> {
        const target = mouseEnterParams.event.target as HTMLElement;
        const bounds = target.getBoundingClientRect();
        const parentBounds = mouseEnterParams.event.target.offsetParent.getBoundingClientRect();
        this.state.set( 'CurrentLibraryPreview', {
            id: mouseEnterParams.item.id,
            x: ( parentBounds.width + bounds.width ),
            y: bounds.top,
            item: mouseEnterParams.item,
        });
        return EMPTY;
    }

    /**
     * Handle the drag start event on the library tile
     * Hide popover preview if it is open
     * @param event
     * @param data
     */
    public handleDragStart( event: any, data: any ) {
        this.commandService.dispatch( BaseDiagramCommandEvent.changeMouseControlState,
            { state: MouseControlState.Normal });
        if ( !isEqual( this.state.get( 'CurrentLibraryPreview' ), {})) {
            this.state.set( 'CurrentLibraryPreview', {});
        }
        // tslint:disable-next-line:max-line-length
        const target = event.target as HTMLElement;
        const thumb: ( HTMLCanvasElement | HTMLImageElement ) = target.querySelector( '.shape-thumbnail-image' );
        event.dataTransfer.effectAllowed = 'move';
        event.dataTransfer.setData( 'Text', JSON.stringify( data ));
        event.dataTransfer.setData( 'TrackingInfo', JSON.stringify({
            location: this.trackingInfo.location || this.id,
            library: this.title,
        }));
        if ( thumb ) {
            event.dataTransfer.setDragImage( thumb, thumb.width / 2, thumb.height / 2 );
        }
    }

    /**
     * Creates a library item for templates, load required info when needed.
     */
    public createTemplateLibraryItem( def: IShapeDefinition ): ILibraryItem {
        return {
            id: def.defId,
            text: {
                title: def.name,
            },
            popoverCssClass: 'lib-preview-container',
            type: 'template',
            data: def,
            thumbnailType: 'image',
            thumbnailUrl: def.thumbnail,
        };
    }

    public handleClick( event: MouseEvent, def: any ) {
        if ( def.defId || def.type ) {
            const viewPort: Rectangle = this.state.get( 'DiagramViewPort' );
            const centerX = viewPort.centerX - ( def.defaultBounds?.width || 0 ) / 2;
            const centerY = viewPort.centerY - ( def.defaultBounds?.height || 0 ) / 2;
            const trackingData: any = {
                value1Type: 'defId', value1: def.defId,
                value2Type: 'library',
                value2: this.title,
                value3Type: 'location',
                value3: this.trackingInfo?.location,
            };
            Tracker.track( 'left.library.shape.click', trackingData );
            if ( def.type === 'template' ) {
                if (( def.defId as string ).startsWith ( 'creately.stickycard' ) ||
                ( def.defId as string ).startsWith ( 'creately.venn' )) {
                    this.shapeManageService.addTemplateAsShape( def, centerX , centerY );
                } else {
                    this.shapeManageService.addTemplate( def, centerX , centerY );
                }
            } else {
                let entity;
                if ( def.eData ) {
                    const edataId = Object.keys( def.eData )[0];
                    if ( edataId ) {
                        def.eData = { [edataId]: null };
                        if ( def.sidebarEntity ) {
                            const id = def.sidebarEntity.id;
                            const edefId = def.sidebarEntity.edefId;
                            entity =  Object.assign( new EntityModel( id, edefId ), def.sidebarEntity ) ;
                        }
                        def.triggerNewEData = true;

                    }
                }
                this.shapeManageService.addShape( def, centerX , centerY, entity ).subscribe();

                if ( this.tourSvc ) {
                    const onboardingTourStep = this.tourSvc.onboardingTourItems
                    .find( step => step.type === 'add-shape' );

                    if ( onboardingTourStep ) {
                        if ( this.tourSvc.hasOnboardingTourStarted() &&
                            !this.tourSvc.tooltipData.value[onboardingTourStep.stepId].viewed ) {
                            this.tourSvc.markStepAsViewed( onboardingTourStep.stepId, true, true );
                        } else if ( this.tourSvc.currentStep.value?.stepId === onboardingTourStep.stepId ) {
                            this.tourSvc.currentStep.next( null );
                        }
                    }
                }
            }
        }
    }

    /**
     * Clear the preview state when mouse leaves the library tile
     * State will set to empty only if it has been set with data to avoid unnecessary emitting
     */
    protected handleMouseOut(): Observable<unknown> {
        return timer( this.timeout ).pipe(
            tap(() => {
                if ( !this.isMouseHover && !isEqual( this.state.get( 'CurrentLibraryPreview' ), {})) {
                    this.state.set( 'CurrentLibraryPreview' , {});
                }
            }),
        );
    }

    /**
     * Creates a library item for a simple shape
     */
    protected createShapeLibraryItem( def: IShapeDefinition ): ILibraryItem {
        return {
            id: def.defId,
            text: {
                title: def.name,
            },
            popoverCssClass: 'lib-preview-container',
            type: 'shape',
            data: def,
            thumbnailType: 'canvas',
            canvasIdPrefix: 'thumb',
        };
    }

    /**
     * Creates a library item for an icon
     */
    protected createIconLibraryItem( def: IShapeDefinition ): ILibraryItem {
        return {
            id: def.defId,
            text: {
                title: def.name,
            },
            popoverCssClass: 'lib-preview-container',
            type: 'icon',
            data: def,
            thumbnailType: 'canvas',
            canvasIdPrefix: 'thumb',
        };
    }

}

/**
 * Data of the library item
 */
export interface ILibraryItem {
    /**
     * Id of the item
     */
    id: string;

    /**
     * Item icon
     */
    icon?: string;

    /**
     * Displayable names
     */
    text: {
        title?: string,
        line1?: string,
        line2?: string,
        line3?: string,
        line4?: string,
        shapeCount?: any[],
    };

    /**
     * The type of the library item.
     */
    type: 'shape' | 'template' | 'image' | 'entity' | 'icon' | 'task' | 'viz' | 'slides';

    /**
     * Defines the style of the container
     */
    popoverCssClass: string;

    /**
     * Indicates if the library item renders a image (png/svg) or has
     * code that can be used to draw the thumbnail on a canvas.
     */
    thumbnailType: 'image' | 'canvas' | 'text';

    /**
     * If the thumbnailType is 'image' this must contain the url for an image file.
     * If the thumbnailType is 'canvas' this field is not required.
     */
    thumbnailUrl?: string;

    /**
     * This holds the data of the item
     * This can be a model, definition or IDefinitionSummary
     */
    data: any;

    /**
     * Canvas Id prefix will set a unique value for canvas rendering
     */
    canvasIdPrefix?: string;

    /**
     * indicate if the Library item should have a close button
     */
    closeButton?: boolean;

    /**
     * Any child components the library item may have
     */
    children?: any;

    isClosed?: Subject<boolean>;


    // childSubjects?: Subject<boolean>[];

    /**
     * Info to populate the popover
     */
    itemInfo?: any;


    /**
     * parent item ID
     */
    parentId?: string;

    childId?: string;

    itemId?: string;

    indent?: number;


    /**
     * Folder this item belongs to.
     */
    folderId?: string;

    /**
     * The high level group for this item
     * ex. Class or usecase inside the UML models
     */
    grouping?: string;

    /**
     * Does it have any refChanges
     */
    refChangeCount?: number;

    /**
     * If this item is selected
     */
    selected?: boolean;


    /**
     * Draw the library item from the given draw code instructions.
     */
    drawCode?: {
        instructions: string[],
        scaleX?: number,
        scaleY?: number,
        defaultWidth?: number,
        defaultHeight?: number,
    };
}
