import { BehaviorSubject, Observable, defer, from, of, throwError } from 'rxjs';
import { AppConfig, CommandService, StateService } from 'flux-core';
import { Injectable } from '@angular/core';
import { filter, last, map, switchMap, take, tap } from 'rxjs/operators';
import TypesenseInstantSearchAdapter from 'typesense-instantsearch-adapter';
import { BaseDiagramCommandEvent } from '../diagram/command/base-diagram-command-event';
import { UserLocator } from 'flux-user';
import { pick } from 'lodash';
/**
 * This contain typesense user search realated logic
 *
 * @author  Sajeeva
 * @since   14-11-2023
 */
@Injectable()
export class UserTypesenseService {

    /**
     * Typesense user and group collection name
     */
    protected userCollection: string = 'team_user_info';

    /**
     * Typesense instant search adapter
     * NOTE: this can be replace from default typesense client library later
     */
    protected typesenseInstantsearchAdapter: TypesenseInstantSearchAdapter;

    protected _isTeamUser: BehaviorSubject<boolean>;

    constructor(
        protected state: StateService<any, any>,
        protected commandService: CommandService,
        protected userLocater: UserLocator,
    ) {
        this._isTeamUser = new BehaviorSubject( -1 as any );
        this.userLocater.getUserDataAfterUserSubscriptionStarted().pipe(
            filter( userModel => !!userModel ),
            take( 1 ),
            tap( user => {
                this._isTeamUser.next( !!( user.team && user.team.id ));
            }),
        ).subscribe();
    }

    get isTeamUser(): Observable<boolean> {
        return this._isTeamUser.pipe(
            filter( v => v !== -1 as any ),
            take( 1 ),
        );
    }

    /**
     * team user and group search
     * @param searchQuery search query string
     * @returns Observable array of TypesenseObject
     */
    public teamUserAndGroupSearch( searchQuery: string = '*' ): Observable<any> {
        return this.isTeamUser.pipe(
            switchMap( isTeamUser => {
                if ( !isTeamUser ) {
                    return of([]);
                }
                const searchParameters = {
                    q: searchQuery,
                    query_by: 'email,first_name,last_name,team_group_display_name',
                    per_page: 25,
                    page: 1,
                };
                return this.checkTypesenseInstance().pipe(
                    switchMap( _ => this.search( searchParameters )),
                );
            }),
        );
    }

    /**
     *
     * @param searchQuery search query
     * @returns Observable array of TypesenseObject
     */
    public teamUserSearch( searchQuery: string, options: any = {}): Observable<any> {
        return this.isTeamUser.pipe(
            switchMap( isTeamUser => {
                if ( !isTeamUser ) {
                    return of([]);
                }
                const searchParameters = {
                    q: searchQuery,
                    query_by: 'email,first_name,last_name',
                    ...pick( options, [ 'per_page', 'page' ]),
                };
                if (  searchParameters.per_page > 250 ) {
                    // maximum per page limit is 250
                    searchParameters.per_page = 250;
                    // tslint:disable-next-line: no-console
                    console.warn( 'user search per_page limit exceeded, setting to 250' );
                }
                return this.checkTypesenseInstance().pipe(
                    switchMap( _ => this.search( searchParameters )),
                );
            }),
        );
    }

    /**
     *
     * @param searchQuery search query
     * @returns Observable array of TypesenseObject
     */
    public searchTeamUsers( emailsOrNames: string[]): Observable<ITypesenseObject[][]> {
        return this.isTeamUser.pipe(
            switchMap( isTeamUser => {
                if ( !isTeamUser ) {
                    return throwError( new Error( 'User is not a team user' ));
                }
                return this.checkTypesenseInstance().pipe(
                    switchMap( _ => this.searchUsers( emailsOrNames )),
                );
            }),
        );
    }

    protected search( searchParameters: any, cacheTimeSec: number = 120 ): Observable<any> {
        return from(
            this.typesenseInstantsearchAdapter.typesenseClient.collections( this.userCollection )
            .documents()
            .search(
                searchParameters,
                { cacheSearchResultsForSeconds: cacheTimeSec },
            ),
        ).pipe(
            map( result =>
                result.hits.map( h => {
                    const data = h.document as ITypesenseObject;
                    return data;
                })),
        );
    }

    protected async searchUsers( queries: string[]): Promise<ITypesenseObject[][]> {
        return this.typesenseInstantsearchAdapter.typesenseClient.multiSearch
            .perform({
                searches: queries.map( q => ({ q, collection: this.userCollection } as any )),
            }, { query_by: 'email,first_name,last_name' })
            .then( result =>
                result.results.map(( res: any ) => res.hits.map( h => {
                    const data = h.document as ITypesenseObject;
                    return data;
                })),
            );
    }

    /**
     * Check if a Typesense client instance has been created.
     * If not, create a new instance. Additionally,
     * verify the current API key's validity, and if it is invalid,
     * generate a new API key and create a new client instance
     * with the updated API key.
     * @returns void or null
     */
    protected checkTypesenseInstance(): Observable<void> {
        return defer(() => {
            if ( !this.isSearchKeyValid()) {
                return this.getSearchKey().pipe(
                    map( _ => this.initializeTypesenseInstance()),
                );
            } else if ( !this.typesenseInstantsearchAdapter ) {
                return of( this.initializeTypesenseInstance());
            } else {
                return of( null );
            }
        });
    }

    /**
     * initialize typesense instant
     */
    protected initializeTypesenseInstance() {
        const typesenseConfig = this.state.get( 'typesense' );
        this.typesenseInstantsearchAdapter = new TypesenseInstantSearchAdapter({
            server: {
                apiKey: typesenseConfig.searchKey,
                nodes: [
                    {
                        url: AppConfig.get ( 'TYPESENSE_API' ),
                    },
                ],
                cacheSearchResultsForSeconds: 2 * 60,
            },
            /**
             * additionalSearchParameters required when creating the instant and
             * this will not effect any queres. This use when we use TypesenseInstantSearchAdapter
             * with instant search ui components
             */
            additionalSearchParameters: {
                query_by: 'email',
                page: 1,
            },
        });
    }

    /**
     * Get search key from fetchTypesenceSearchKey command
     */
    protected getSearchKey() {
        return this.commandService.dispatch(
            BaseDiagramCommandEvent.fetchTypesenceSearchKey,
        ).pipe(
            last(),
        );
    }

    /**
     * Check the whether api key availabe in state and
     * if availabe validate the api key with expired date
     * @returns boolean
     */
    protected isSearchKeyValid() {
        if ( this.state.get( 'typesense' )
                && this.state.get( 'typesense' ).expiresAt ) {
            const expiresAt = this.state.get( 'typesense' ).expiresAt;
            const currentTimestamp = new Date().getTime();
            return expiresAt > currentTimestamp;
        }
        return false;
    }
}

export interface ITypesenseObject {
    ID: string;
    email: string;
    first_name: string;
    group_plan_def_name: string;
    group_plan_id: string;
    id: string;
    last_name: string;
    team_group_display_name: string;
    team_group_id: string;
    uid?: string;
}
